import * as React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Form, TextFieldForm } from 'shared/forms'
import { useUpdateUserProfileMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetUserProfile } from '../data/Queries'
import { PermissionsTransferList } from 'modules/user/components/PermissionsTransferList'
import { Permission } from 'auth/models/Permission'

interface UpdateForm {
  name: string
  label: string
}

const useStyles = makeStyles({
  form: {
    width: 800,
    margin: '0 auto',
  },
})

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
  label: yup.string().required('La descripción es un campo requerido'),
})

const formId = 'UpdateUserProfile'
const dialogId = 'UserProfileEditFormDialog'

export const Update = () => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = React.useState(true)

  const [permissions, setPermissions] = React.useState<Permission[]>([])

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetUserProfile({ variables: { id }, fetchPolicy: 'cache-and-network' })
  const [updateUserProfile, { loading: isUpdating }] = useUpdateUserProfileMutation()

  const loading = isGetting || isUpdating

  const profile = React.useMemo(() => {
    return data?.getUserProfile
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      label: '',
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (profile) {
      setPermissions(profile.permissions ?? [])
      reset(profile)
    }
  }, [profile, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener el perfil de usuario. Intentelo más tarde'

      if (error.message === 'UserProfile not found') {
        msg = 'Perfil de usuario no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/user-profiles')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      const arePermissionsDirty = profile.permissions !== permissions
      if (!isDirty && !arePermissionsDirty) {
        return
      }

      await updateUserProfile({
        variables: {
          data: {
            id: profile.id,
            ...values,
            permissions: permissions.map(permission => permission.id),
          },
        },
      })

      enqueueSnackbar('Perfil de usuario actualizado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al actualizar el perfil de usuario. Intentelo más tarde', { variant: 'error' })
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Editar perfil de usuario</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId, className: classes.form }}>
          <h3>Datos generales</h3>
          <Divider className="mt-4 mb-16" />
          <TextFieldForm
            name="name"
            label="Nombre"
            placeholder="Ingresar nombre"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <TextFieldForm
            name="label"
            label="Descripción"
            placeholder="Ingresar descripción"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          />
          {profile ? (
            <>
              <h3 className="mt-8">Asignar permisos</h3>
              <Divider className="mt-4 mb-16" />
              <PermissionsTransferList initialValue={profile.permissions} onSelectionChanged={setPermissions}></PermissionsTransferList>
            </>
          ) : null}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
