import { gql, useMutation } from '@apollo/client'
import { Status } from 'models/BaseModel'
import { PaymentMethod } from 'modules/income/models/Income'
import { Frequency } from '../models/Frequency'
import { ScheduleOutcome } from '../models/ScheduleOutcome'
import { ListScheduleOutcomeFragment } from './Queries'

export interface ChangeScheduleOutcomeStatusPayload {
  id: string
  status: Status
}

const CHANGE_SCHEDULE_OUTCOME_STATUS = gql`
  mutation ChangeScheduleOutcomeStatus($data: ChangeScheduleOutcomeStatusPayload!) {
    changeScheduleOutcomeStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeScheduleOutcomeStatusMutation = () => {
  const tuple = useMutation<{ changeScheduleOutcomeStatus: ScheduleOutcome }, { data: ChangeScheduleOutcomeStatusPayload }>(
    CHANGE_SCHEDULE_OUTCOME_STATUS,
  )

  return tuple
}

export interface UpdateScheduleOutcomePayload {
  id: string
  name: string
  amount: number
  companyId?: string
  categoryId?: string
  period?: string
  frequency: Frequency
  paymentMethod?: PaymentMethod
  organizationId?: string
  bankAccountId?: string
  creditCardId?: string
}

const UPDATE_SCHEDULE_OUTCOME = gql`
  mutation UpdateScheduleOutcome($data: UpdateScheduleOutcomePayload!) {
    updateScheduleOutcome(data: $data) {
      ...ListScheduleOutcomeFragment
    }
  }
  ${ListScheduleOutcomeFragment}
`

export const useUpdateScheduleOutcomeMutation = () => {
  const tuple = useMutation<{ updateScheduleOutcome: ScheduleOutcome }, { data: UpdateScheduleOutcomePayload }>(UPDATE_SCHEDULE_OUTCOME)

  return tuple
}

const DELETE_SCHEDULE_OUTCOME = gql`
  mutation DeleteScheduleOutcome($id: String!) {
    deleteScheduleOutcome(id: $id) {
      id
      status
    }
  }
`

export const useDeleteScheduleOutcomeMutation = () => {
  const tuple = useMutation<{ deleteScheduleOutcome: ScheduleOutcome }, { id: string }>(DELETE_SCHEDULE_OUTCOME)

  return tuple
}

export interface RegisterScheduleOutcomePayload {
  name: string
  amount: number
  companyId?: string
  categoryId?: string
  period?: string
  frequency: Frequency
  paymentMethod?: PaymentMethod
  organizationId?: string
  bankAccountId?: string
  creditCardId?: string
}

const REGISTER_SCHEDULE_OUTCOME = gql`
  mutation CreateScheduleOutcome($data: RegisterScheduleOutcomePayload!) {
    registerScheduleOutcome(data: $data) {
      ...ListScheduleOutcomeFragment
    }
  }
  ${ListScheduleOutcomeFragment}
`

export const useRegisterScheduleOutcomeMutation = () => {
  const tuple = useMutation<{ registerScheduleOutcome: ScheduleOutcome }, { data: RegisterScheduleOutcomePayload }>(
    REGISTER_SCHEDULE_OUTCOME,
  )

  return tuple
}
