import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Organization } from '../models/Organization'

const LIST_ORGANIZATION = gql`
  query {
    listOrganization {
      id
      name
      rfc
      businessName
      account {
        id
        balance
        initialBalance
      }
      tenantId
      status
      createdAt
    }
  }
`
export type ListOrganizationResponse = { listOrganization: Organization[] }

export const useOrganizationList = (options?: QueryHookOptions<ListOrganizationResponse>) => {
  const result = useQuery<ListOrganizationResponse>(LIST_ORGANIZATION, options)
  return result
}

const GET_ORGANIZATION = gql`
  query GetOrganization($id: String!) {
    getOrganization(id: $id) {
      id
      name
      rfc
      businessName
      account {
        id
        initialBalance
        balance
      }
      tenantId
      status
    }
  }
`
export type GetOrganizationResponse = { getOrganization: Organization }
export interface GetOrganizationVars {
  id: string
}

export const useGetOrganization = (options?: QueryHookOptions<GetOrganizationResponse, GetOrganizationVars>) => {
  const result = useQuery<GetOrganizationResponse, GetOrganizationVars>(GET_ORGANIZATION, options)
  return result
}
