import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useBankList } from '../data/Queries'
import { Bank } from '../models/Bank'
import { Column } from 'react-table'
import { Card } from '@material-ui/core'

export const List = () => {
  const { data: companyListData } = useBankList({ pollInterval: 5000, fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' })

  const columns = React.useMemo<Column<Bank>[]>(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return companyListData?.listBank ?? []
  }, [companyListData])

  const tableInstance = useDataTable({
    selectable: false,
    columns,
    data,
  })

  return (
    <Card>
      <DataTable {...tableInstance}></DataTable>
    </Card>
  )
}
