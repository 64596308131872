import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useRegisterBankAccountMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { BankSelect } from 'modules/bank/components/BankSelect'
import { AccountType, accountTypes } from '../models/BankAccount'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'

interface CreateForm {
  initialBalance: number
  accountNumber: string
  name: string
  ownerName: string
  accountType: AccountType
  bankId: string
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la cuenta es un campo requerido'),
  // eslint-disable-next-line no-template-curly-in-string
  accountNumber: yup.string().max(24, 'El máximo de caracteres es ${max}').required('El número de cuenta es un campo requerido'),
  initialBalance: yup.number().required('El saldo inicial es un campo requerido'),
  ownerName: yup.string().required('El nombre del titular es un campo requerido'),
  accountType: yup.string().required('El tipo de cuenta es un campo requerido'),
  bankId: yup.string().required('El banco es un campo requerido'),
})

const dialogId = 'accounts/create-dialog'
const formId = 'accounts/create-form'

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [addBankAccount, { loading }] = useRegisterBankAccountMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: '',
      name: '',
      ownerName: '',
      initialBalance: 1000,
      accountType: AccountType.checking_account,
      bankId: '',
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await addBankAccount({
        variables: { data: values },
      })

      enqueueSnackbar('Cuenta registrada correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al registrar la cuenta. Intentelo más tarde', { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/bankaccounts')
  }

  return (
    <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Registrar cuenta</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <TextFieldForm
            name="name"
            label="Nombre de la cuenta"
            placeholder="Ingresar nombre"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="initialBalance"
                label="Saldo inicial"
                placeholder="Ingresar saldo inicial"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                name="accountNumber"
                label="Número de cuenta"
                placeholder="Ingresar número de cuenta"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                transform={{
                  output: ev => (/^[0-9]+$/.test(ev.target.value) ? ev.target.value : ev.target.value.slice(0, -1)),
                }}
                inputProps={{
                  maxLength: 24,
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <TextFieldForm
            name="ownerName"
            label="Titular"
            placeholder="Ingresar titular"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <SelectForm
                name="accountType"
                label="Tipo de cuenta"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {accountTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.text}
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
            <Grid xs={6} item>
              <BankSelect name="bankId" disabled={loading}></BankSelect>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
