import { useMutation, gql } from '@apollo/client'
import { CreditCard } from '../models/CreditCard'
import { Status } from 'models/BaseModel'
import { MoveType } from 'modules/category-move/models/CategoryMove'

export interface ChangeCreditCardStatusPayload {
  id: string
  status: Status
}

const CHANGE_CREDIT_CARD_STATUS = gql`
  mutation ChangeCreditCardStatus($data: ChangeCreditCardStatusPayload!) {
    changeCreditCardStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeCreditCardStatusMutation = () => {
  const tuple = useMutation<{ changeCreditCardStatus: CreditCard }, { data: ChangeCreditCardStatusPayload }>(CHANGE_CREDIT_CARD_STATUS)

  return tuple
}

export interface UpdateCreditCardPayload {
  id: string
  accountNumber: string
  name: string
  ownerName: string
  bankId: string
  endDay: number
  limitDay: number
  creditLimit: number
  initialBalance?: number
}

const UPDATE_CREDIT_CARD = gql`
  mutation UpdateCreditCard($data: UpdateCreditCardPayload!) {
    updateCreditCard(data: $data) {
      id
      accountNumber
      name
      ownerName
      bank {
        id
        name
        status
      }
      account {
        id
        initialBalance
        balance
      }
      endDay
      limitDay
      creditLimit
      status
      tenantId
    }
  }
`

export const useUpdateCreditCardMutation = () => {
  const tuple = useMutation<{ updateCreditCard: CreditCard }, { data: UpdateCreditCardPayload }>(UPDATE_CREDIT_CARD)

  return tuple
}

const DELETE_CREDIT_CARD = gql`
  mutation DeleteCreditCard($id: String!) {
    deleteCreditCard(id: $id) {
      id
      status
    }
  }
`

export const useDeleteCreditCardMutation = () => {
  const tuple = useMutation<{ deleteCreditCard: CreditCard }, { id: string }>(DELETE_CREDIT_CARD)

  return tuple
}

export interface RegisterCreditCardPayload {
  accountNumber: string
  name: string
  ownerName: string
  bankId: string
  endDay: number
  limitDay: number
  creditLimit: number
  balance: number
  moveType: MoveType
}

const REGISTER_CREDIT_CARD = gql`
  mutation CreateCreditCard($data: RegisterCreditCardPayload!) {
    registerCreditCard(data: $data) {
      id
      accountNumber
      name
      ownerName
      account {
        id
        initialBalance
        balance
      }
      bank {
        id
        name
        status
      }
      endDay
      limitDay
      creditLimit
      status
      tenantId
    }
  }
`

export const useRegisterCreditCardMutation = () => {
  const tuple = useMutation<{ registerCreditCard: CreditCard }, { data: RegisterCreditCardPayload }>(REGISTER_CREDIT_CARD)

  return tuple
}
