import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { ScheduleOutcome } from '../models/ScheduleOutcome'

export interface ListScheduleOutcomeResponse {
  listScheduleOutcome: ScheduleOutcome[]
}

export const ListScheduleOutcomeFragment = gql`
  fragment ListScheduleOutcomeFragment on ScheduleOutcome {
    id
    name
    amount
    company {
      id
      name
    }
    category {
      id
      name
    }
    organization {
      id
      name
    }
    bankAccount {
      id
      name
      accountNumber
    }
    creditCard {
      id
      name
      accountNumber
    }
    paymentMethod
    period
    frequency
    status
    createdAt
    tenantId
  }
`

const LIST_SCHEDULE_OUTCOME = gql`
  query {
    listScheduleOutcome {
      ...ListScheduleOutcomeFragment
    }
  }
  ${ListScheduleOutcomeFragment}
`

export const useScheduleOutcomeList = (options?: QueryHookOptions<ListScheduleOutcomeResponse>) => {
  const result = useQuery<ListScheduleOutcomeResponse>(LIST_SCHEDULE_OUTCOME, options)
  return result
}

const GET_SCHEDULE_OUTCOME = gql`
  query GetScheduleOutcome($id: String!) {
    getScheduleOutcome(id: $id) {
      ...ListScheduleOutcomeFragment
    }
  }
  ${ListScheduleOutcomeFragment}
`
export type GetScheduleOutcomeResponse = { getScheduleOutcome: ScheduleOutcome }
export interface GetScheduleOutcomeVars {
  id: string
}

export const useGetScheduleOutcome = (options?: QueryHookOptions<GetScheduleOutcomeResponse, GetScheduleOutcomeVars>) => {
  const result = useQuery<GetScheduleOutcomeResponse, GetScheduleOutcomeVars>(GET_SCHEDULE_OUTCOME, options)
  return result
}
