import { TenantModel } from 'models/TenantModel'
import { Account } from 'modules/account/models/Account'
import { Company } from 'modules/company/models/Company'
import { Organization } from 'modules/organization/models/Organization'
import { TypeCredit } from 'modules/type-credit/models/TypeCredit'

export enum CreditPaymentType {
  biweekly = 'biweekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  unique = 'unique',
}

export enum CreditKind {
  provider = 'provider',
  customer = 'customer',
  creditor = 'creditor',
}

export const creditPaymentTypeOptions: { text: string; value: CreditPaymentType }[] = [
  {
    text: 'Único al vencimiento',
    value: CreditPaymentType.unique,
  },
  {
    text: 'Trimestral',
    value: CreditPaymentType.quarterly,
  },
  {
    text: 'Mensual',
    value: CreditPaymentType.monthly,
  },
  {
    text: 'Quincenal',
    value: CreditPaymentType.biweekly,
  },
]

export const creditKindLabels: { [key in CreditKind]: string } = {
  [CreditKind.provider]: 'Proveedor',
  [CreditKind.customer]: 'Cliente',
  [CreditKind.creditor]: 'Acreedor',
}

export const creditKindOptions: { text: string; value: CreditKind }[] = Object.entries(creditKindLabels).map(([value, text]) => ({
  value: value as CreditKind,
  text,
}))

export class Credit extends TenantModel {
  name: string
  typeCredit: TypeCredit
  organization: Organization
  company: Company
  totalDebt: number
  debt?: number
  term: number
  paymentType: CreditPaymentType
  interest: number
  initDate: Date
  pay?: number
  lastPaymentDate?: Date
  account: Account
  kind?: CreditKind

  constructor(data: Partial<Credit>) {
    const { typeCredit, organization, company, account, ...values } = data
    super(data)

    if (typeCredit) {
      this.typeCredit = new TypeCredit(typeCredit)
    }

    if (organization) {
      this.organization = new Organization(organization)
    }

    if (company) {
      this.company = new Company(company)
    }

    if (account) {
      this.account = new Account(account)
    }

    Object.assign(this, values)
  }
}
