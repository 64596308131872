import { Typography } from '@material-ui/core'
import * as React from 'react'
import { SimplePage } from '../../SimplePage'
import { AddButton } from '../AddButton'

export interface ListPageProps {
  title: string
  hideAddButton?: boolean
  actions?: React.ReactNode
}

export const ListPage: React.FC<ListPageProps> = ({ title, hideAddButton, children, actions }) => {
  return (
    <SimplePage
      header={
        <div className="flex h-full w-full py-24 px-40 items-center justify-between">
          <div>
            <Typography variant="h6">{title}</Typography>
          </div>
          <div>
            {!hideAddButton ? (
              <AddButton
                title="Agregar"
                style={{
                  marginRight: actions ? 20 : 0,
                }}
              ></AddButton>
            ) : null}
            {actions}
          </div>
        </div>
      }
    >
      <div className="p-32">{children}</div>
    </SimplePage>
  )
}
