import * as React from 'react'
import { FormProvider, SubmitErrorHandler, SubmitHandler, UseFormMethods } from 'react-hook-form'

export interface FormProps<T> extends UseFormMethods<T> {
  onSubmit: SubmitHandler<T>
  onError?: SubmitErrorHandler<T>
  formProps?: JSX.IntrinsicElements['form']
}

export const Form = <T extends Record<string, any>>({
  onSubmit,
  onError,
  formProps,
  children,
  ...formMethods
}: React.PropsWithChildren<FormProps<T>>) => {
  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form {...formProps} onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        {children}
      </form>
    </FormProvider>
  )
}
