import { User } from 'auth/models/User'
import { Status } from 'models/BaseModel'
import { StatusMenu } from 'shared/crud'
import { useChangeUserStatusMutation } from '../data/Mutations'

export interface StatusUpdateProps {
  user: User
}

export const StatusUpdate = ({ user }: StatusUpdateProps) => {
  const { id, status } = user

  const [updateStatus, { loading }] = useChangeUserStatusMutation()

  const handleChange = (newStatus: Status) => {
    updateStatus({ variables: { data: { id, status: newStatus } } })
  }

  return <StatusMenu value={status} onChange={handleChange} disabled={loading}></StatusMenu>
}
