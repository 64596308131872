import { Dict } from './Types'

// number assertions
export function isNumber(value: any): value is number {
  return typeof value === 'number'
}

// string assertions
export function isString(value: any): value is string {
  return Object.prototype.toString.call(value) === '[object String]'
}

// object assertions
export const isObject = (value: any): value is Dict => {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function') && !isArray(value)
}

export const isEmptyObject = (value: any) => isObject(value) && Object.keys(value).length === 0

// array assertions
export function isArray<T>(value: any): value is Array<T> {
  return Array.isArray(value)
}

export const isEmptyArray = (value: any) => isArray(value) && value.length === 0

// function assertions
export function isFunction(value: any): value is Function {
  return typeof value === 'function'
}

// generic assertions
export const isUndefined = (value: any): value is undefined => typeof value === 'undefined' || value === undefined

export const isDefined = (value: any) => typeof value !== 'undefined' && value !== undefined

export const isEmpty = (value: any) => {
  if (isArray(value)) return isEmptyArray(value)
  if (isObject(value)) return isEmptyObject(value)
  if (value == null || value === '') return true
  return false
}

export const __DEV__ = process.env.NODE_ENV !== 'production'
