import * as React from 'react'
import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@material-ui/core'
import { useController, useFormContext } from 'react-hook-form'

export interface CheckboxFormProps extends CheckboxProps {
  label?: React.ReactNode
}

export const CheckboxForm = ({ name, defaultValue, label, ...checkboxProps }: CheckboxFormProps) => {
  const { control } = useFormContext()

  const {
    field: { ref, value, onChange: formOnChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const onChange: FormControlLabelProps['onChange'] = (_ev, checked) => {
    formOnChange(checked)
  }

  return <FormControlLabel {...inputProps} control={<Checkbox {...checkboxProps} />} onChange={onChange} label={label} />
}
