import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { CategoryMove, CategoryOutcomeType, MoveType } from '../models/CategoryMove'

export type ListCategoryMoveResponse = { listCategoryMove: CategoryMove[] }

export type ListCategoryMoveChildsResponse = { listCategoryMoveChilds: CategoryMove[] }

export interface ListCategoryMovePayload {
  moveType: MoveType
  outcomeType?: CategoryOutcomeType
  onlyChilds?: boolean
}

const LIST_CATEGORY_MOVE = gql`
  query ListCategoryMove($data: ListCategoryMovePayload!) {
    listCategoryMove(data: $data) {
      id
      name
      description
      moveType
      outcomeType
      parent {
        id
        name
      }
      status
      createdAt
      tenantId
    }
  }
`

export const useCategoryMoveList = (options?: QueryHookOptions<ListCategoryMoveResponse, { data: ListCategoryMovePayload }>) => {
  const result = useQuery<ListCategoryMoveResponse, { data: ListCategoryMovePayload }>(LIST_CATEGORY_MOVE, options)
  return result
}

const GET_CATEGORY_MOVE = gql`
  query GetCategoryMove($id: String!) {
    getCategoryMove(id: $id) {
      id
      name
      description
      moveType
      outcomeType
      parent {
        id
        name
      }
      status
      tenantId
    }
  }
`
export type GetCategoryMoveResponse = { getCategoryMove: CategoryMove }
export interface GetCategoryMoveVars {
  id: string
}

export const useGetCategoryMove = (options?: QueryHookOptions<GetCategoryMoveResponse, GetCategoryMoveVars>) => {
  const result = useQuery<GetCategoryMoveResponse, GetCategoryMoveVars>(GET_CATEGORY_MOVE, options)
  return result
}
