import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, TextFieldForm } from 'shared/forms'
import { useRegisterCustomerCreditMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { Organization } from 'modules/organization/models/Organization'
import { Company, CompanyType } from 'modules/company/models/Company'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { DatePickerForm } from 'shared/forms/DatePickerForm'
import dayjs from 'dayjs'

interface CreateForm {
  name: string
  organization: Organization
  company: Company
  totalDebt: number
  debt?: number
  initDate: dayjs.Dayjs
  lastPaymentDate?: dayjs.Dayjs
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
  organization: yup.object().nullable().required('Debe asignar una organización al crédito'),
  company: yup.object().nullable().required('Debe asignar un cliente al crédito'),
  totalDebt: yup.number().nullable().required('La deuda total es requerida'),
  debt: yup.number().nullable(),
  initDate: yup.object().nullable().required('La fecha de inicio es requerida'),
})

const dialogId = 'customer-credits/create-dialog'
const formId = 'customer-credits/create-form'

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const [addCredit, { loading }] = useRegisterCustomerCreditMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      organization: null,
      company: null,
      totalDebt: null,
      debt: null,
      initDate: null,
      lastPaymentDate: null,
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { initDate, company, organization, lastPaymentDate, ...data } = values

      await addCredit({
        variables: {
          data: {
            initDate: initDate.toDate(),
            lastPaymentDate: lastPaymentDate?.toDate(),
            customerId: company?.id,
            organizationId: organization?.id,
            ...data,
          },
        },
      })

      enqueueSnackbar('Crédito registrado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al registrar el crédito. Intentelo más tarde', { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/customer-credits')
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Registrar crédito a cliente</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="name"
                label="Nombre del crédito"
                placeholder="Ingresar nombre del crédito"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <CompanyAutocomplete
                name="company"
                type={[CompanyType.customer]}
                inputProps={{ label: 'Cliente', variant: 'outlined', required: true }}
                placeholder="Buscar Cliente"
                className="mb-16"
                fullWidth
              ></CompanyAutocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <OrganizationAutocomplete
                name="organization"
                inputProps={{ label: 'Organización', variant: 'outlined', required: true }}
                placeholder="Buscar organización"
                className="mb-16"
                fullWidth
              ></OrganizationAutocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="totalDebt"
                label="Deuda total"
                placeholder="Ingresar monto total de la deuda"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="debt"
                label="Deuda actual"
                placeholder="Ingresar monto actual de la deuda"
                variant="outlined"
                className="mb-16"
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <DatePickerForm
                name="initDate"
                label="Fecha de inicio del crédito"
                inputProps={{ variant: 'outlined', required: true, fullWidth: true }}
                maxDate={dayjs()}
              ></DatePickerForm>
            </Grid>
            <Grid xs={6} item>
              <LastPaymentDate></LastPaymentDate>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function LastPaymentDate() {
  const { getValues, control } = useFormContext()

  const initDate = useWatch<dayjs.Dayjs | null>({
    control,
    name: 'initDate',
    defaultValue: getValues('initDate'),
  })

  return initDate ? (
    <DatePickerForm
      name="lastPaymentDate"
      label="Fecha de último pago"
      inputProps={{ variant: 'outlined', fullWidth: true }}
      minDate={initDate}
      maxDate={dayjs()}
    ></DatePickerForm>
  ) : null
}
