import * as React from 'react'
import * as yup from 'yup'
import { Button, Card, CardProps, Grid, MenuItem, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Form, SelectForm } from 'shared/forms'
import { ListAccountTransferPayload } from '../data/Queries'
import dayjs from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateNumberList } from 'core/utils'
import clsx from 'clsx'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { Organization } from 'modules/organization/models/Organization'
import { useConstant } from 'shared/hooks'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'

export interface FilterProps extends CardProps {
  loading?: boolean
  onFilter: (payload: ListAccountTransferPayload, values: FilterForm) => void
}

export interface FilterForm {
  organization?: Organization
  bankAccount?: BankAccount
  year: number
  month: number
}

const schema = yup.object().shape({
  organization: yup.object().nullable(),
  bankAccount: yup.object().nullable(),
  year: yup.number().required('Debe seleccionar un año'),
  month: yup.number().required('Debe seleccionar un mes'),
})

const baseYear = 2021

const years = generateNumberList(dayjs().year() - baseYear + 1).map(n => n + baseYear)

const buildFilter = ({ organization, bankAccount, year, month }: FilterForm) => {
  const monthDate = dayjs(`${month + 1}/${year}`, 'M/YYYY')

  return {
    organizationId: organization?.id,
    bankAccountId: bankAccount?.id,
    initDate: monthDate.startOf('month').toDate(),
    endDate: monthDate.endOf('month').toDate(),
  }
}

export const Filter = ({ onFilter, className, loading, ...props }: FilterProps) => {
  const now = dayjs()

  const defaultValues = useConstant(() => ({
    year: now.year(),
    month: now.month(),
    organization: null,
    bankAccount: null,
  }))

  const formInstance = useForm<FilterForm>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  React.useEffect(() => {
    onFilter(buildFilter(defaultValues), defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  const onSubmit = (values: FilterForm) => {
    onFilter(buildFilter(values), values)
  }

  return (
    <Card {...props} className={clsx('flex flex-row items-center', className)}>
      <Typography className="flex-none" variant="body1">
        Filtrar listado
      </Typography>
      <Form formProps={{ className: 'flex-grow ml-48 flex flex-row items-center' }} onSubmit={onSubmit} {...formInstance}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <SelectForm name="year" label="Seleccionar año" fullWidth disabled={loading} required>
              {years.map(year => {
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              })}
            </SelectForm>
            <OrganizationAutocomplete
              name="organization"
              inputProps={{ label: 'Seleccionar organización' }}
              placeholder="Buscar organización..."
              fullWidth
              disabled={loading}
            ></OrganizationAutocomplete>
          </Grid>
          <Grid item xs={3}>
            <SelectForm name="month" label="Seleccionar año" fullWidth disabled={loading} required>
              {dayjs.months().map((month, index) => {
                return (
                  <MenuItem key={month} value={index}>
                    {month}
                  </MenuItem>
                )
              })}
            </SelectForm>
            <BankAccountAutocomplete
              name="bankAccount"
              inputProps={{ label: 'Cuenta de banco' }}
              placeholder="Buscar cuenta de banco"
              className="mb-16"
              fullWidth
            ></BankAccountAutocomplete>
          </Grid>
          <Grid item xs={3} className="flex items-center">
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Card>
  )
}
