import * as React from 'react'
import { Paper, Select, Typography } from '@material-ui/core'
import { FilterType, getChartFilter, getLimits } from '../utils'
import { useNetIncomeTotalsState } from './UseNetIncomeTotals'
import { NetIncomeTotals } from './NetIncomeTotals'

const defaultFilter: FilterType = 'monthly'

export const NetIncome = () => {
  const netIncomeTotalsState = useNetIncomeTotalsState({
    pollInterval: 10000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const { getTotals, getChartData, totalsState, chartState } = netIncomeTotalsState

  const loading = totalsState?.loading || chartState?.loading

  React.useEffect(() => {
    const totals = getLimits(defaultFilter)
    const chart = getChartFilter(defaultFilter)
    getTotals({
      variables: {
        totals,
      },
    })
    getChartData({
      variables: {
        chart: {
          payload: chart,
        },
      },
    })
  }, [getTotals, getChartData])

  const onFilter = (ev: React.ChangeEvent<{ name?: string; value: unknown }>, _child: React.ReactNode) => {
    const value = ev.target.value as FilterType

    const totals = getLimits(value)
    const chart = getChartFilter(value)

    getTotals({
      variables: {
        totals,
      },
    })
    getChartData({
      variables: {
        chart: {
          payload: chart,
        },
      },
    })
  }

  return (
    <Paper className="h-full">
      <div className="flex items-center justify-between p-20 h-64">
        <Typography className="text-16 font-medium">Ingreso neto</Typography>

        <Select
          native
          onChange={onFilter}
          defaultValue={defaultFilter}
          inputProps={{
            name: 'income-filter',
          }}
          className="font-medium opacity-75"
          disabled={loading}
          disableUnderline
        >
          <option value="monthly">Último Mes</option>
          <option value="annual">Año</option>
        </Select>
      </div>
      <NetIncomeTotals {...netIncomeTotalsState}></NetIncomeTotals>
    </Paper>
  )
}
