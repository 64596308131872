import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import clsx from 'clsx'
import { formatCurrency } from 'shared/utils'

interface Summary {
  name: string
  amount: number
}

export interface AmountSummaryProps {
  summary: Summary[]
  total: Summary
}

const useStyles = makeStyles({
  summaryRow: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
})

export const AmountSummary = ({ summary, total }: AmountSummaryProps) => {
  const classes = useStyles()
  const totals = [...summary, total]

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Método de pago</TableCell>
            <TableCell>Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {totals.map((total, index) => {
            const isLast = totals.length === index + 1

            const cellClassName = clsx({ 'font-bold': isLast })

            return (
              <TableRow key={total.name} className={classes.summaryRow}>
                <TableCell className={cellClassName}>{total.name}</TableCell>
                <TableCell className={cellClassName}>{formatCurrency(total.amount)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
