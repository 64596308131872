import { gql, useMutation } from '@apollo/client'
import { Outcome, OutcomeType, PaymentMethod } from '../models/Outcome'
import { ListOutcomeFragment } from './Queries'

export class RegisterOutcomePayloadV2 {
  isPayment: boolean
  outcome?: RegisterOutcomePayload
  payment?: RegisterPaymentPayload
}

export interface RegisterOutcomePayload {
  date: Date
  amount: number
  notes?: string
  companyId: string
  categoryId: string
  organizationId: string
  paymentMethod: PaymentMethod
  bankAccountId?: string
  creditCardId?: string
  hasTaxReceipt?: boolean
  isDirectDebit?: boolean
  creditId?: string
}

export interface RegisterPaymentPayload extends RegisterOutcomePayload {
  outcomeType: OutcomeType
  debtId: string
  capital?: number
  interest?: number
  otherAmount?: number
}

const REGISTER_OUTCOME = gql`
  mutation CreateOutcome($data: RegisterOutcomePayloadV2!) {
    registerOutcome(data: $data) {
      ...ListOutcomeFragment
    }
  }
  ${ListOutcomeFragment}
`

export const useRegisterOutcomeMutation = () => {
  const tuple = useMutation<{ registerOutcome: Outcome }, { data: RegisterOutcomePayloadV2 }>(REGISTER_OUTCOME)

  return tuple
}

const DELETE_OUTCOME = gql`
  mutation DeleteOutcome($id: String!) {
    deleteOutcome(id: $id) {
      id
      status
    }
  }
`

export const useDeleteOutcomeMutation = () => {
  const tuple = useMutation<{ deleteOutcome: Outcome }, { id: string }>(DELETE_OUTCOME)

  return tuple
}

export interface UpdateOutcomePayload {
  id: string
  companyId?: string
  categoryId?: string
}

const UPDATE_OUTCOME = gql`
  mutation UpdateOutcome($data: UpdateOutcomePayload!) {
    updateOutcome(data: $data) {
      id
      company {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`

export const useUpdateOutcomeMutation = () => {
  return useMutation<{ updateOutcome: Outcome }, { data: UpdateOutcomePayload }>(UPDATE_OUTCOME)
}
