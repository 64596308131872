import Decimal from 'decimal.js-light'
import { CategoryAmount } from '../../../category-move/models/CategoryMove'
import { CategoryAmountSegment } from '../data/UseCategoryAmountsSegments'

export interface CategoryAmountElement extends CategoryAmount {
  segments: Record<string, string>
  children?: CategoryAmountElement[]
}

export type CategoryAmountTree = CategoryAmountElement[]

interface CategoryAmountTreeStore {
  entities: Record<string, CategoryAmount>
  segmentAmounts: Record<string, Record<string, string>>
}

export function buildCategoryAmountTree(
  data: CategoryAmountSegment[],
): CategoryAmountTree {
  const store: CategoryAmountTreeStore = {
    entities: {},
    segmentAmounts: {},
  }

  for (const segment of data) {
    for (const categoryAmount of segment.results) {
      store.entities[categoryAmount.categoryId] = categoryAmount
      if (!store.segmentAmounts[categoryAmount.categoryId]) {
        store.segmentAmounts[categoryAmount.categoryId] = {}
      }
      store.segmentAmounts[categoryAmount.categoryId][segment.name] =
        categoryAmount.amount
    }
  }

  const rootCategories = Object.values(store.entities).filter(d => !d.parentId)
  return rootCategories.map(root => resolveItem(root, store))
}

function resolveItem(
  categoryAmount: CategoryAmount,
  store: CategoryAmountTreeStore,
): CategoryAmountElement {
  const children = Object.values(store.entities)
    .filter(d => d.parentId === categoryAmount.categoryId)
    .map(child => resolveItem(child, store))

  const segments = store.segmentAmounts[categoryAmount.categoryId]
  const categoryAmountSegments = {}
  let amount = new Decimal(0)
  for (const segment in segments) {
    const segmentAmount = children.reduce((sum, child) => {
      return sum.plus(child.segments[segment])
    }, new Decimal(segments[segment]))
    categoryAmountSegments[segment] = segmentAmount.toString()
    amount = amount.plus(segmentAmount)
  }
  return {
    ...categoryAmount,
    segments: categoryAmountSegments,
    amount: amount.toString(),
    children,
  }
}
