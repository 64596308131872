import * as React from 'react'
import * as yup from 'yup'
import { Button, Card, CardProps, Grid, MenuItem, Typography } from '@material-ui/core'
import { useForm, useWatch, useFormContext } from 'react-hook-form'
import { Form, SelectForm } from 'shared/forms'
import { ListAccountBalancePayload } from './data/Queries'
import dayjs from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateNumberList } from 'core/utils'
import clsx from 'clsx'
import { useConstant } from 'shared/hooks'
import { AccountType, accountTypes } from 'modules/account/models/Account'
import { CreditCardAutocomplete } from 'modules/credit-card/components/CreditCardAutocomplete'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CreditKind } from 'modules/credit/models/Credit'
import { CreditCard } from 'modules/credit-card/models/CreditCard'
import { DatePickerForm } from 'shared/forms/DatePickerForm'
import { CreditKindSelect } from 'modules/credit/components/CreditKindSelect'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { Company, companyTypeLabels } from 'modules/company/models/Company'
import { getCreditCompanyTypes } from 'modules/credit/utils/CompanyType'

export interface FilterProps extends CardProps {
  loading?: boolean
  onFilter: (payload: ListAccountBalancePayload, values: FilterForm) => void
}

export interface FilterForm {
  accountType: AccountType | 'all'
  creditKind: CreditKind | 'all'
  bankAccount?: BankAccount
  creditCompany?: Company
  creditCard?: CreditCard
  month: dayjs.Dayjs
}

const schema = yup.object().shape({
  accounType: yup.string().isOptional('all'),
  bankAccount: yup.object().nullable(),
  creditKind: yup.string().when('accounType', {
    is: (accounType: FilterForm['accountType']) => accounType === AccountType.credit,
    then: yup.string().required('Debe seleccionar un tipo de crédito'),
  }),
  creditCompany: yup.object().nullable(),
  creditCard: yup.object().nullable(),
  month: yup.object().required('Debe seleccionar un mes'),
})

const baseYear = 2021

const years = generateNumberList(dayjs().year() - baseYear + 1).map(n => n + baseYear)
const minYearDate = dayjs(`${years[0]}/1`, 'YYYY/M')
const maxYearDate = dayjs(`${years[years.length - 1]}/12`, 'YYYY/M')

const buildFilter = ({ accountType, bankAccount, creditKind, creditCompany, creditCard, month }: FilterForm) => {
  return {
    accountType: accountType === 'all' ? null : accountType,
    bankAccountId: bankAccount?.id,
    creditKind: creditKind === 'all' ? null : creditKind,
    creditCompanyId: creditCompany?.id,
    creditCardId: creditCard?.id,
    initDate: month.startOf('month').toDate(),
    endDate: month.endOf('month').toDate(),
  }
}

export const Filter = ({ onFilter, className, loading, ...props }: FilterProps) => {
  const now = dayjs()

  const defaultValues = useConstant<FilterForm>(() => ({
    accountType: 'all',
    bankAccount: null,
    creditKind: 'all',
    creditCompany: null,
    creditCard: null,
    month: now,
  }))

  const formInstance = useForm<FilterForm>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  React.useEffect(() => {
    onFilter(buildFilter(defaultValues), defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  const onSubmit = (values: FilterForm) => {
    onFilter(buildFilter(values), values)
  }

  return (
    <Card {...props} className={clsx('flex flex-row items-center', className)}>
      <Typography className="flex-none" variant="body1">
        Filtrar listado
      </Typography>
      <Form formProps={{ className: 'flex-grow ml-48 flex flex-row items-center' }} onSubmit={onSubmit} {...formInstance}>
        <Grid container spacing={5}>
          <Grid item xs={2}>
            <DatePickerForm
              name="month"
              label="Seleccionar mes"
              views={['year', 'month']}
              minDate={minYearDate}
              maxDate={maxYearDate}
              disabled={loading}
            ></DatePickerForm>
          </Grid>
          <Grid item xs={2}>
            <SelectForm name="accountType" label="Seleccionar tipo de cuenta" fullWidth disabled={loading}>
              <MenuItem value="all">
                <em>Todos</em>
              </MenuItem>
              {accountTypes.map(pm => (
                <MenuItem key={pm.value} value={pm.value}>
                  {pm.text}
                </MenuItem>
              ))}
            </SelectForm>
          </Grid>
          <CreditKindForm></CreditKindForm>
          <Grid item xs={2}>
            <CreditForm></CreditForm>
            <CreditCardForm></CreditCardForm>
            <BankAccountForm></BankAccountForm>
          </Grid>
          <Grid item xs={2} className="flex items-center">
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Card>
  )
}

function CreditKindForm() {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<AccountType>({
    control,
    name: 'accountType',
    defaultValue: getValues('accountType'),
  })

  return accountType === AccountType.credit ? (
    <Grid item xs={2}>
      <CreditKindSelect name="creditKind" label="Tipo de crédito" fullWidth></CreditKindSelect>
    </Grid>
  ) : null
}

function useCreditCompanyType() {
  const { getValues, control } = useFormContext()

  const creditKind = useWatch<FilterForm['creditKind']>({
    control,
    name: 'creditKind',
    defaultValue: getValues('creditKind'),
  })

  const companyTypes = getCreditCompanyTypes(creditKind === 'all' ? undefined : creditKind)
  return companyTypes
}

function CreditForm() {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<AccountType>({
    control,
    name: 'accountType',
    defaultValue: getValues('accountType'),
  })

  const creditCompanyType = useCreditCompanyType()
  const label = creditCompanyType.map(type => companyTypeLabels[type]).join('/')

  return accountType === AccountType.credit ? (
    <CompanyAutocomplete
      name="creditCompany"
      type={creditCompanyType}
      inputProps={{ label }}
      placeholder={`Buscar ${label}`}
      fullWidth
    ></CompanyAutocomplete>
  ) : null
}

function CreditCardForm() {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<AccountType>({
    control,
    name: 'accountType',
    defaultValue: getValues('accountType'),
  })

  return accountType === AccountType.creditCard ? (
    <CreditCardAutocomplete
      name="creditCard"
      inputProps={{ label: 'Tarjeta de crédito' }}
      placeholder="Buscar el tarjeta"
      fullWidth
    ></CreditCardAutocomplete>
  ) : null
}

function BankAccountForm() {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<AccountType>({
    control,
    name: 'accountType',
    defaultValue: getValues('accountType'),
  })

  return accountType === AccountType.bankAccount ? (
    <BankAccountAutocomplete
      name="bankAccount"
      inputProps={{ label: 'Cuenta de banco' }}
      placeholder="Buscar cuenta de banco"
      fullWidth
    ></BankAccountAutocomplete>
  ) : null
}
