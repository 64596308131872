import * as React from 'react'
import { LocalizationProvider } from '@material-ui/pickers'
import DayJSAdapter from '@material-ui/pickers/adapter/dayjs'
import dayjs from 'dayjs'

export const DateProvider: React.FC = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={DayJSAdapter} dateLibInstance={dayjs} locale="es">
      {children}
    </LocalizationProvider>
  )
}
