import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@material-ui/core'
import { SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useUpdateScheduleOutcomeMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetScheduleOutcome } from '../data/Queries'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { Company, CompanyType } from 'modules/company/models/Company'
import { CategoryMove, MoveType } from 'modules/category-move/models/CategoryMove'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { CategoryMoveAutocomplete } from 'modules/category-move/components/CategoryMoveAutocomplete'
import { Frequency, frequencyOptions } from '../models/Frequency'
import { formatCronString, getDayOptions, getMonthOptions, parseCron } from '../utils/cron'
import { DaySelect, MonthsSelect } from './Lib'
import { Organization } from 'modules/organization/models/Organization'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CreditCard } from 'modules/credit-card/models/CreditCard'
import { PaymentMethod, paymentMethods } from 'modules/income/models/Income'
import { CreditCardAutocomplete } from 'modules/credit-card/components/CreditCardAutocomplete'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'

interface UpdateForm {
  name: string
  amount: number
  company?: Company
  category?: CategoryMove
  frequency: Frequency
  months?: string
  days?: string
  organization?: Organization
  paymentMethod?: string
  bankAccount?: BankAccount
  creditCard?: CreditCard
}

const allowedPaymentMethods = paymentMethods.map(pm => pm.value)

const schema = yup.object().shape({
  name: yup.string().required('El nombre del egreso es requerido'),
  amount: yup.number().nullable().moreThan(0, 'Debe ingresar un monto mayor').required('El monto del egreso es requerido'),
  company: yup.object().nullable(),
  category: yup.object().nullable(),
  organization: yup
    .object()
    .nullable()
    .when('paymentMethod', {
      is: paymentMethod => paymentMethod && paymentMethod === PaymentMethod.cash,
      then: yup.object().nullable().required('Debe asignar una organización al egreso'),
    }),
  paymentMethod: yup.string().isOptional('none').oneOf(allowedPaymentMethods, 'El método de pago no es correcto'),
  bankAccount: yup.object().when('paymentMethod', {
    is: paymentMethod => paymentMethod && paymentMethod !== PaymentMethod.cash && paymentMethod !== PaymentMethod.credit_card,
    then: yup.object().nullable().required('Debe asignar una cuenta bancaria al egreso'),
  }),
  creditCard: yup.object().when('paymentMethod', {
    is: paymentMethod => paymentMethod && paymentMethod === PaymentMethod.credit_card,
    then: yup.object().nullable().required('Debe asignar una tarjeta de crédito al egreso'),
  }),
})

const formId = 'UpdateScheduleOutcome'
const dialogId = 'ScheduleOutcomeEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [isEntered, setIsEntered] = React.useState(false)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetScheduleOutcome({ variables: { id }, fetchPolicy: 'cache-and-network' })
  const [updateScheduleOutcome, { loading: isUpdating }] = useUpdateScheduleOutcomeMutation()

  const loading = isGetting || isUpdating

  const outcome = React.useMemo(() => {
    return data?.getScheduleOutcome
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      amount: null,
      company: null,
      category: null,
      frequency: Frequency.annual,
      months: getMonthOptions(Frequency.annual)[0].value,
      days: getDayOptions(Frequency.annual)[0].value,
      organization: null,
      paymentMethod: 'none',
      bankAccount: null,
      creditCard: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (isEntered && outcome) {
      const { period, paymentMethod, ...data } = outcome

      const { daysOfMonth, months } = parseCron(period)

      reset({ days: daysOfMonth, months, paymentMethod: paymentMethod || 'none', ...data })
    }
  }, [outcome, reset, isEntered])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener el egreso. Intentelo más tarde'

      if (error.message === 'ScheduleOutcome not found') {
        msg = 'Egreso no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onEntered = () => {
    setIsEntered(true)
  }

  const onExited = () => {
    history.push('/schedule-outcomes')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { company, category, months, days, organization, bankAccount, creditCard, paymentMethod, ...data } = values

      await updateScheduleOutcome({
        variables: {
          data: {
            id: outcome.id,
            companyId: company?.id,
            categoryId: category?.id,
            organizationId: organization?.id,
            bankAccountId: bankAccount?.id,
            creditCardId: creditCard?.id,
            paymentMethod: paymentMethod !== 'none' ? (paymentMethod as PaymentMethod) : undefined,
            period: formatCronString(days, months),
            ...data,
          },
        },
      })

      enqueueSnackbar('Egreso actualizado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al actualizar el egreso. Intentelo más tarde', { variant: 'error' })
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onEntered={onEntered}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar el egreso</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="name"
                label="Nombre"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="amount"
                label="Monto"
                placeholder="Ingresar monto del egreso"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CompanyAutocomplete
                name="company"
                type={[CompanyType.supplier, CompanyType.employee]}
                inputProps={{ label: 'Proveedor/Empleado', variant: 'outlined' }}
                placeholder="Buscar Proveedor/Empleado"
                className="mb-16"
                fullWidth
              ></CompanyAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <CategoryMoveAutocomplete
                type={MoveType.outcome}
                name="category"
                inputProps={{ label: 'Categoría', variant: 'outlined', required: true }}
                placeholder="Buscar categoría"
                className="mb-16"
                fullWidth
              ></CategoryMoveAutocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <OrganizationAutocomplete
                name="organization"
                inputProps={{ label: 'Organización', variant: 'outlined' }}
                placeholder="Buscar organización"
                className="mb-16"
                fullWidth
              ></OrganizationAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <SelectForm name="paymentMethod" label="Método de pago" variant="outlined" className="mb-16" fullWidth disabled={loading}>
                <MenuItem value="none">
                  <em>Seleccionar método de pago</em>
                </MenuItem>
                {paymentMethods.map(pm => (
                  <MenuItem key={pm.value} value={pm.value}>
                    {pm.text}
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
          </Grid>
          <BankAccountForm></BankAccountForm>
          <CreditCardForm></CreditCardForm>
          <Grid container spacing={2}>
            <Grid xs={3} item>
              <SelectForm name="frequency" label="Frequencia" variant="outlined" className="mb-16" required fullWidth disabled={loading}>
                {frequencyOptions.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.text}
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
            <MonthsSelect disabled={loading}></MonthsSelect>
            <DaySelect disabled={loading}></DaySelect>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function BankAccountForm() {
  const { getValues, setValue, control } = useFormContext()

  const paymentMethod = useWatch<string>({
    control,
    name: 'paymentMethod',
    defaultValue: getValues('paymentMethod'),
  })

  const isBank = paymentMethod !== 'none' && paymentMethod !== PaymentMethod.cash && paymentMethod !== PaymentMethod.credit_card

  React.useEffect(() => {
    if (!isBank) {
      setValue('bankAccount', null)
    }
  }, [isBank, setValue])

  return isBank ? (
    <BankAccountAutocomplete
      name="bankAccount"
      inputProps={{ label: 'Cuenta de banco', variant: 'outlined', required: true }}
      placeholder="Buscar cuenta de banco"
      className="mb-16"
      fullWidth
    ></BankAccountAutocomplete>
  ) : null
}

function CreditCardForm() {
  const { getValues, setValue, control } = useFormContext()

  const paymentMethod = useWatch<string>({
    control,
    name: 'paymentMethod',
    defaultValue: getValues('paymentMethod'),
  })

  const isCredit = paymentMethod !== 'none' && paymentMethod === PaymentMethod.credit_card

  React.useEffect(() => {
    if (!isCredit) {
      setValue('creditCard', null)
    }
  }, [isCredit, setValue])

  return isCredit ? (
    <CreditCardAutocomplete
      name="creditCard"
      inputProps={{ label: 'Tarjeta de crédito', variant: 'outlined', required: true }}
      placeholder="Buscar tarjeta"
      className="mb-16"
      fullWidth
    ></CreditCardAutocomplete>
  ) : null
}
