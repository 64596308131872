import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client'

export interface ListTotalIncomeAndOutcomePayload {
  initDate: Date
  endDate: Date
  organizationId?: string
}

export interface IncomeAndOutcomeDayPayload {
  payload: {
    name: string
    initDate: Date
    endDate: Date
  }[]
  organizationId?: string
}

export interface NetIncomeTotalsResponse {
  getIncomeAndOutcome: {
    name: string
    amount: number
  }[]
}

export interface NetIncomeTotalsVars {
  totals: ListTotalIncomeAndOutcomePayload
}

const NET_INCOME_TOTALS = gql`
  query GetIncomeAndOutcome($totals: ListTotalIncomeAndOutcomePayload!) {
    getIncomeAndOutcome(data: $totals) {
      name
      amount
    }
  }
`

export const useNetIncomeTotals = (options?: QueryHookOptions<NetIncomeTotalsResponse, NetIncomeTotalsVars>) => {
  const result = useLazyQuery<NetIncomeTotalsResponse, NetIncomeTotalsVars>(NET_INCOME_TOTALS, options)
  return result
}

export interface NetIncomeChartDataResponse {
  getIncomeAndOutcomeDay: {
    name: string
    income: number
    outcome: number
  }[]
}

export interface NetIncomeChartDataVars {
  chart: IncomeAndOutcomeDayPayload
}

const NET_INCOME_CHART_DATA = gql`
  query GetIncomeAndOutcomeDay($chart: IncomeAndOutcomeDayPayload!) {
    getIncomeAndOutcomeDay(data: $chart) {
      name
      income
      outcome
    }
  }
`

export const useNetIncomeChartData = (options?: QueryHookOptions<NetIncomeChartDataResponse, NetIncomeChartDataVars>) => {
  const result = useLazyQuery<NetIncomeChartDataResponse, NetIncomeChartDataVars>(NET_INCOME_CHART_DATA, options)
  return result
}

interface ListExpenseCategoriesResponse {
  getCategoryOutcome: {
    name: string
    amount: number
  }[]
}

interface ListExpenseCategoriesVars {
  data: ListTotalIncomeAndOutcomePayload
}

const NET_EXPENSE_CATEGORIES = gql`
  query GetIncomeAndOutcome($data: ListTotalIncomeAndOutcomePayload!) {
    getCategoryOutcome(data: $data) {
      name
      amount
    }
  }
`

export const useExpenseCategoriesTotals = (options?: QueryHookOptions<ListExpenseCategoriesResponse, ListExpenseCategoriesVars>) => {
  const result = useLazyQuery<ListExpenseCategoriesResponse, ListExpenseCategoriesVars>(NET_EXPENSE_CATEGORIES, options)
  return result
}
