import * as React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useControllableProp } from 'shared/hooks'
import { createContext } from 'shared/utils'

export interface CrudContext {
  name: string
  url: string
}

export interface CrudProviderProps extends Omit<CrudContext, 'url'> {
  url?: string
}

const [Provider, useCrudContext, Context] = createContext<CrudContext>({
  name: 'CrudContext',
  errorMessage: 'You forgot to use CrudPage',
})

export const CrudProvider: React.FC<CrudProviderProps> = ({ children, url: propUrl, name }) => {
  const { url: matchUrl } = useRouteMatch()

  const [, url] = useControllableProp(propUrl, matchUrl)

  const state = React.useMemo(() => {
    return { url, name }
  }, [url, name])

  return <Provider value={state}>{children}</Provider>
}

export const CrudConsumer = Context.Consumer

export { useCrudContext }
