import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Company } from '../models/Company'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteCompanyMutation } from '../data/Mutations'

export interface DeleteProps {
  companies: Company[]
}

const dialogId = 'CompanyDeleteDialog'

export const Delete = ({ companies }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteCompany] = useDeleteCompanyMutation()

  const deleteCompanies = async () => {
    try {
      await Promise.all(companies.map(u => deleteCompany({ variables: { id: u.id } })))
      enqueueSnackbar(`${companies.length} compañias eliminadas correctamente`, { variant: 'success' })
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(`Ha ocurrido un error al eliminar las compañias . Intentelo más tarde`, { variant: 'error' })
    }
  }

  return (
    <>
      <Tooltip title="Eliminar compañias">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onClose={close} aria-labelledby={dialogId}>
        <DialogTitle id={dialogId}>Eliminar compañias</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Deseas eliminar las siguientes compañias?</DialogContentText>
          <List dense>
            {companies.map(company => (
              <ListItem key={company.id}>
                <ListItemText primary={`- ${company.name}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteCompanies} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
