import { InputBaseComponentProps, TextField, TextFieldProps } from '@material-ui/core'
import NumberFormat from 'react-number-format'

function NumberFormatCustom(props: Omit<InputBaseComponentProps, 'defaultValue'>) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        })
      }}
      thousandSeparator
      isNumericString
      suffix="%"
    />
  )
}

export const PercentTextField = ({ InputProps: propInputProps = {}, ...props }: TextFieldProps) => {
  const { inputComponent, ...InputProps } = propInputProps

  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatCustom,
        ...InputProps,
      }}
      {...props}
    ></TextField>
  )
}
