import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useRegisterCompanyMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { CompanyType, companyTypes } from '../models/Company'
import { CompanyTypeItem } from './CompanyTypeItem'
import { ApolloError } from '@apollo/client'

interface CreateForm {
  name: string
  rfc?: string
  businessName: string
  email: string
  contact: string
  description: string
  phone: string
  companyType: CompanyType[]
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la compañía es un campo requerido'),
  rfc: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(12, 'El mínimo de caracteres es ${min}')
    .isOptional(),
  businessName: yup.string().isOptional(),
  email: yup.string().email('El formato del correo electrónico no es válido').isOptional(),
  contact: yup.string().isOptional(),
  description: yup.string().isOptional(),
  // eslint-disable-next-line no-template-curly-in-string
  phone: yup.string().min(10, 'El mínimo de caracteres es ${min}').required('El teléfono es un campo requerido'),
  companyType: yup.array().min(1, 'Debes elegir al menos un tipo de compañía').required('El tipo de compañía es requerido'),
})

const dialogId = 'companies/create-dialog'
const formId = 'companies/create-form'

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const [addCompany, { loading }] = useRegisterCompanyMutation()

  const { enqueueSnackbar } = useSnackbar()

  const { url } = useRouteMatch()

  const history = useHistory()

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      rfc: '',
      businessName: '',
      email: '',
      contact: '',
      description: '',
      phone: '',
      companyType: [],
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await addCompany({
        variables: { data: values },
      })

      enqueueSnackbar('Compañía registrado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      let message = 'Ha ocurrido un error al registrar la compañía. Intentelo más tarde'
      if (e instanceof ApolloError) {
        if (e.message.includes('Company already exists')) {
          const [, registeredCompany] = e.message.replace('Company already exists - RFC', '').trim().split(' belongs to company ')

          message = `El RFC capturado le pertenece a la compañía '${registeredCompany}'`
        }
      }
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push(url.replace('/create', ''))
  }

  const renderSelectedTypes = (selected: string[]) => {
    return selected.map(s => companyTypes.find(ct => ct.value === s).text).join(', ')
  }

  return (
    <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Registrar compañía</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="name"
                label="Nombre"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                name="rfc"
                label="RFC"
                placeholder="Ingresar rfc"
                variant="outlined"
                className="mb-16"
                fullWidth
                transform={{
                  output: ev => ev.target.value.toUpperCase(),
                }}
                inputProps={{
                  maxLength: 13,
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <TextFieldForm
            name="businessName"
            label="Razón social"
            placeholder="Ingresar razón social"
            variant="outlined"
            className="mb-16"
            fullWidth
            disabled={loading}
          />
          <TextFieldForm
            name="email"
            label="Correo electrónico"
            placeholder="Ingresar correo electrónico"
            variant="outlined"
            className="mb-16"
            fullWidth
            disabled={loading}
          />
          <TextFieldForm
            name="contact"
            label="Nombre del contacto"
            placeholder="Ingresar nombre del contacto"
            variant="outlined"
            className="mb-16"
            fullWidth
            disabled={loading}
          />
          <TextFieldForm
            name="description"
            label="Descripción"
            placeholder="Ingresar descripción"
            variant="outlined"
            className="mb-16"
            rows={3}
            multiline
            fullWidth
            disabled={loading}
          />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="phone"
                label="Teléfono"
                placeholder="Ingresar teléfono"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                inputProps={{
                  maxLength: 10,
                }}
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <SelectForm
                name="companyType"
                label="Tipo"
                variant="outlined"
                className="mb-16"
                multiple
                required
                fullWidth
                renderValue={renderSelectedTypes}
                disabled={loading}
              >
                {companyTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    <CompanyTypeItem {...type}></CompanyTypeItem>
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
