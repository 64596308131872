import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useCompanyList } from '../data/Queries'
import { Company, CompanyType, companyTypes } from '../models/Company'
import { Column, Row } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { StatusUpdate } from './StatusUpdate'
import { Delete } from './Delete'
import { Card } from '@material-ui/core'
import { DeleteButton } from 'shared/crud/DeleteButton'
import { ExportCsvButton, ExportCsvButtonProps } from 'shared/crud/ExportCsvButton'
import { getRowsForDeletion } from 'shared/utils'

function getCompanyTypeName(companyType: CompanyType) {
  return companyTypes.find(ct => ct.value === companyType).text ?? ''
}

export const List = ({ companyType }: { companyType?: string }) => {
  const { data: companyListData } = useCompanyList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      data: { companyType: companyType ? [companyType as CompanyType] : undefined },
    },
  })

  const columns = React.useMemo<Column<Company>[]>(() => {
    let columnsDefinition: Column<Company>[] = [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'RFC',
        accessor: 'rfc',
      },
      {
        Header: 'Correo electrónico',
        accessor: 'email',
      },
      {
        Header: 'Teléfono',
        accessor: 'phone',
      },
    ]

    if (!companyType) {
      columnsDefinition.push({
        id: 'companyType',
        Header: 'Tipo',
        accessor: ({ companyType }) => companyType.map(getCompanyTypeName).join(', '),
      })
    }

    columnsDefinition = columnsDefinition.concat([
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Estatus',
        accessor: company => <StatusUpdate company={company}></StatusUpdate>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: company => (
          <EditButton title="Editar compañia" aria-label="editar compañia" id={company.id} tenantId={company.tenantId}></EditButton>
        ),
      },
    ])

    return columnsDefinition
  }, [companyType])

  const data = React.useMemo(() => {
    return companyListData?.listCompany ?? []
  }, [companyListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<Company>>(() => {
    return {
      columns: [
        {
          id: 'name',
          header: 'Nombre',
          cell: row => row.name,
        },
        {
          id: 'rfc',
          header: 'RFC',
          cell: row => row.rfc,
        },
        {
          id: 'email',
          header: 'Correo electrónico',
          cell: row => row.email,
        },
        {
          id: 'phone',
          header: 'Teléfono',
          cell: row => row.phone,
        },
        {
          id: 'companyType',
          header: 'Tipo',
          cell: row => row.companyType.map(getCompanyTypeName).join(', '),
        },
        {
          id: 'createdAt',
          header: 'Fecha de creación',
          cell: row => dayjs(row.createdAt).format('L'),
        },
        {
          id: 'status',
          header: 'Estatus',
          cell: row => row.status,
        },
      ],
      filename: `Compañías - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data])

  const toolBarContent = (selectedRows: Row<Company>[]) => {
    return selectedRows.length ? (
      <DeleteButton>
        <Delete companies={getRowsForDeletion(selectedRows)}></Delete>
      </DeleteButton>
    ) : (
      <ExportCsvButton {...csvProps}></ExportCsvButton>
    )
  }

  return (
    <Card>
      <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
    </Card>
  )
}
