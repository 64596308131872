import { Edit } from '@material-ui/icons'
import { IconNavButton, IconNavButtonProps } from './components/IconNavButton'
import { useCrudContext } from './CrudPage/CrudContext'
import { WithUpdateAction } from './WithUpdate'

export type EditButtonProps = Omit<IconNavButtonProps, 'children' | 'to'> & { id: string; tenantId?: string | null }

export const EditButton = ({ tenantId, ...props }: EditButtonProps) => {
  const { url } = useCrudContext()

  return (
    <WithUpdateAction tenantId={tenantId}>
      <IconNavButton to={`${url}/edit/${props.id}`} {...props}>
        <Edit></Edit>
      </IconNavButton>
    </WithUpdateAction>
  )
}
