import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, TextFieldForm } from 'shared/forms'
import { useRegisterOrganizationMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'

interface CreateForm {
  name: string
  rfc?: string
  businessName: string
  balance: number
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la organización es un campo requerido'),
  // eslint-disable-next-line no-template-curly-in-string
  rfc: yup.string().min(12, 'El minimo de caracteres es ${min}').isOptional(),
  businessName: yup.string(),
  balance: yup.number().required('El saldo inicial es requerido'),
})

const dialogId = 'organizations/create-dialog'
const formId = 'organizations/create-form'

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const [addOrganization, { loading }] = useRegisterOrganizationMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      rfc: '',
      businessName: '',
      balance: 0,
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await addOrganization({
        variables: { data: values },
      })

      enqueueSnackbar('Organización registrada correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al registrar la organización. Intentelo más tarde', { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/organizations')
  }

  return (
    <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Registrar organización</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="name"
                label="Nombre"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                name="rfc"
                label="RFC"
                placeholder="Ingresar rfc"
                variant="outlined"
                className="mb-16"
                fullWidth
                transform={{
                  output: ev => ev.target.value.toUpperCase(),
                }}
                inputProps={{
                  maxLength: 13,
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={8} item>
              <TextFieldForm
                name="businessName"
                label="Razón social"
                placeholder="Ingresar razón social"
                variant="outlined"
                className="mb-16"
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={4} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="balance"
                label="Saldo inicial"
                placeholder="Ingresar saldo inicial"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
