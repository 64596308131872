import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, Typography } from '@material-ui/core'
import { SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { CheckboxForm, Form, SelectForm, TextFieldForm } from 'shared/forms'
import { RegisterIncomePayload, useRegisterIncomeMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { PaymentMethod, paymentMethods } from '../models/Income'
import dayjs from 'dayjs'
import { Company, CompanyType } from 'modules/company/models/Company'
import { CategoryMove, MoveType } from 'modules/category-move/models/CategoryMove'
import { Organization } from 'modules/organization/models/Organization'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { CategoryMoveAutocomplete } from 'modules/category-move/components/CategoryMoveAutocomplete'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { DatePickerForm } from 'shared/forms/DatePickerForm'
import { SATPaymentMethodAutocomplete } from 'modules/sat-payment-method/components/SATPaymentMethodAutocomplete'
import { SATPaymentMethod } from 'modules/sat-payment-method/models/SATPaymentMethod'
import { Credit, CreditKind } from 'modules/credit/models/Credit'
import { CreditAutocomplete } from 'modules/credit/components/CreditAutocomplete'

interface CreateForm {
  date: dayjs.Dayjs
  amount: number
  notes?: string
  client: Company
  employee: Company
  category: CategoryMove
  organization: Organization
  paymentMethod: PaymentMethod
  bankAccount?: BankAccount
  hasReceipt: boolean
  receiptType?: string
  issueDate?: dayjs.Dayjs
  dueDate?: dayjs.Dayjs
  code?: string
  SATPaymentMethod?: SATPaymentMethod
  concept?: string
  subtotal?: number
  iva?: number
  customerCredit: Credit

  isPayment: number
  paymentCredit: Credit
  capital?: number
  interest?: number
  otherAmount?: number
}

const allowedPaymentMethods = paymentMethods.map(pm => pm.value)
const nonBankPaymentMethods = [PaymentMethod.cash, PaymentMethod.customer_credit]

const schema = yup.object().shape({
  amount: yup.number().nullable().moreThan(0, 'Debe ingresar un monto mayor').required('El monto del ingreso es requerido'),
  date: yup
    .object()
    .nullable()
    .test('test-date', 'La fecha debe ser mayor al 2021', function (value) {
      const minDate = dayjs('01-01-2021', 'DD-MM-YYYY')
      const ddate = dayjs(new Date(value.toString()))
      return !ddate.isBefore(minDate)
    })
    .required('La fecha del ingreso es requerida'),
  notes: yup.string().isOptional(),
  client: yup.object().nullable().required('Debe asignar un cliente al ingreso'),
  employee: yup.object().nullable(),
  category: yup.object().nullable().required('Debe asignar una categoría al ingreso'),
  organization: yup.object().nullable().required('Debe asignar una organización al ingreso'),
  paymentMethod: yup
    .string()
    .oneOf(allowedPaymentMethods, 'El método de pago no es correcto')
    .required('Debe asignar un método de pago al ingreso'),
  bankAccount: yup.object().when('paymentMethod', {
    is: paymentMethod => !nonBankPaymentMethods.includes(paymentMethod),
    then: yup.object().nullable().required('Debe asignar una cuenta bancaria al ingreso'),
  }),
  customerCredit: yup.object().when('paymentMethod', {
    is: paymentMethod => paymentMethod === PaymentMethod.customer_credit,
    then: yup.object().nullable().required('Debe asignar un crédito de cliente al ingreso'),
  }),

  hasReceipt: yup.boolean(),
  receiptType: yup.string().isOptional('none'),
  code: yup
    .string()
    .isOptional()
    .when('receiptType', {
      is: receiptType => !!receiptType,
      then: yup.string().isOptional().required('Debe asignar un folio al comprobante'),
    }),
  concept: yup
    .string()
    .isOptional()
    .when('receiptType', {
      is: receiptType => !!receiptType,
      then: yup.string().isOptional().required('Debe asignar un concepto al comprobante'),
    }),
  issueDate: yup
    .object()
    .nullable()
    .when('receiptType', {
      is: receiptType => receiptType === 'invoice',
      then: yup.object().nullable().required('Debe asignar una fecha de emisión'),
    }),
  dueDate: yup
    .object()
    .nullable()
    .when('receiptType', {
      is: receiptType => receiptType === 'invoice',
      then: yup.object().nullable().required('Debe asignar una fecha de vencimiento'),
    }),
  SATPaymentMethod: yup
    .object()
    .nullable()
    .when('receiptType', {
      is: receiptType => receiptType === 'invoice',
      then: yup.object().nullable().required('Debe asignar una forma de pago a la factura'),
    }),
  subtotal: yup
    .number()
    .nullable()
    .when('receiptType', {
      is: receiptType => receiptType === 'invoice',
      then: yup.number().nullable().required('Debe asignar el subtotal a la factura'),
    }),
  iva: yup
    .number()
    .nullable()
    .when('receiptType', {
      is: receiptType => receiptType === 'invoice',
      then: yup.number().nullable().required('Debe asignar el iva a la factura'),
    }),

  isPayment: yup.number(),
  paymentCredit: yup.object().when('isPayment', {
    is: isPayment => !!isPayment,
    then: yup.object().nullable().required('Debe elegir el crédito a abonar'),
  }),
  capital: yup.number().when('isPayment', {
    is: isPayment => !!isPayment,
    then: yup
      .number()
      .nullable()
      .moreThan(0, 'Debe ingresar un monto mayor a 0')
      .required('El monto a capital del pago que esta realizando es requerido'),
  }),
  interest: yup.number().nullable(),
  otherAmount: yup.number().nullable(),
})

const dialogId = 'incomes/create-dialog'
const formId = 'incomes/create-form'

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const [addIncome, { loading }] = useRegisterIncomeMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: null,
      date: dayjs(),
      notes: '',
      client: null,
      employee: null,
      category: null,
      organization: null,
      paymentMethod: PaymentMethod.cash,
      bankAccount: null,
      customerCredit: null,
      hasReceipt: false,
      receiptType: 'none',
      issueDate: null,
      dueDate: null,
      code: '',
      SATPaymentMethod: null,
      concept: '',
      subtotal: null,
      iva: null,

      isPayment: 0,
      paymentCredit: null,
      capital: null,
      otherAmount: null,
      interest: null,
    },
  })

  const { formState, setValue, watch } = formInstance

  const { hasReceipt, receiptType, client } = watch(['hasReceipt', 'receiptType', 'client'])

  const { isDirty } = formState

  React.useEffect(() => {
    if (!hasReceipt) {
      setValue('receiptType', 'none')
    }
  }, [hasReceipt, setValue])

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const {
        date,
        organization,
        category,
        bankAccount,
        customerCredit,
        client,
        employee,
        issueDate,
        dueDate,
        hasReceipt,
        receiptType,
        SATPaymentMethod,

        isPayment,
        paymentCredit,
        interest,
        otherAmount,
        capital,
        ...incomeData
      } = values

      const payload: RegisterIncomePayload = {
        date: date.toDate(),
        organizationId: organization.id,
        categoryId: category.id,
        bankAccountId: bankAccount?.id,
        customerCreditId: customerCredit?.id,
        clientId: client.id,
        employeeId: employee?.id,
        issueDate: issueDate?.toDate(),
        dueDate: dueDate?.toDate(),
        hasInvoice: hasReceipt && receiptType === 'invoice',
        hasTicket: hasReceipt && receiptType === 'ticket',
        SATPaymentMethodId: SATPaymentMethod?.id,
        ...incomeData,
      }

      if (isPayment) {
        payload.payment = {
          capital,
          interest,
          otherAmount,
          debtId: paymentCredit.id,
        }
      }

      await addIncome({
        variables: {
          data: payload,
        },
      })

      enqueueSnackbar('Ingreso registrado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al registrar el ingreso. Intentelo más tarde', { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/incomes')
  }

  const renderReceiptForm = () => {
    let lastPart

    if (receiptType === 'invoice') {
      lastPart = (
        <>
          <SATPaymentMethodAutocomplete
            name="SATPaymentMethod"
            inputProps={{ label: 'Forma de pago', variant: 'outlined', required: true }}
            placeholder="Buscar forma de pago"
            className="mb-16"
            fullWidth
          ></SATPaymentMethodAutocomplete>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <DatePickerForm
                name="issueDate"
                label="Fecha de emisión de la factura"
                inputProps={{ variant: 'outlined', required: true, fullWidth: true }}
                maxDate={dayjs()}
              ></DatePickerForm>
            </Grid>
            <Grid xs={6} item>
              <DatePickerForm
                name="dueDate"
                label="Fecha de vencimiento"
                inputProps={{ variant: 'outlined', required: true, fullWidth: true }}
                maxDate={dayjs()}
              ></DatePickerForm>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="subtotal"
                label="Subtotal"
                placeholder="Ingresar subtotal"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="iva"
                label="IVA"
                placeholder="Ingresar iva"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
        </>
      )
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <TextFieldForm
              name="code"
              label="Folio"
              placeholder="Ingresar el folio"
              variant="outlined"
              className="mb-16"
              required
              fullWidth
              disabled={loading}
            />
          </Grid>
          <Grid xs={6} item>
            <TextFieldForm
              name="concept"
              label="Concepto"
              placeholder="Ingresar el concepto"
              variant="outlined"
              className="mb-16"
              required
              fullWidth
              disabled={loading}
            />
          </Grid>
        </Grid>
        {lastPart}
      </>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEnforceFocus
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Registrar ingreso</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} onError={console.warn} {...formInstance}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <SelectForm name="isPayment" label="Operación" variant="outlined" className="mb-16" required fullWidth disabled={loading}>
                <MenuItem value={0}>Ingreso</MenuItem>
                <MenuItem value={1}>Abono a crédito cliente</MenuItem>
              </SelectForm>
            </Grid>
            <Grid xs={6} item>
              <CreditToPayForm></CreditToPayForm>
            </Grid>
          </Grid>
          <ClientForm></ClientForm>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CompanyAutocomplete
                name="employee"
                type={[CompanyType.employee]}
                inputProps={{ label: 'Empleado', variant: 'outlined' }}
                placeholder="Buscar empleado"
                className="mb-16"
                fullWidth
              ></CompanyAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <OrganizationForm></OrganizationForm>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CategoryMoveAutocomplete
                type={MoveType.income}
                name="category"
                inputProps={{ label: 'Categoría', variant: 'outlined', required: true }}
                placeholder="Buscar categoría"
                className="mb-16"
                fullWidth
              ></CategoryMoveAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <SelectForm
                name="paymentMethod"
                label="Método de pago"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {paymentMethods.map(pm => (
                  <MenuItem key={pm.value} value={pm.value}>
                    {pm.text}
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
          </Grid>
          <CustomerCreditForm></CustomerCreditForm>
          <BankAccountForm></BankAccountForm>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <DatePickerForm
                name="date"
                label="Fecha del ingreso"
                inputProps={{ variant: 'outlined', required: true, fullWidth: true }}
                minDate={dayjs('01-01-2021', 'DD-MM-YYYY')}
                maxDate={dayjs()}
              ></DatePickerForm>
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="amount"
                label="Monto"
                placeholder="Ingresar monto del ingreso"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldForm
                name="notes"
                label="Notas"
                placeholder="Ingresar notas"
                variant="outlined"
                className="mb-16"
                rows={3}
                multiline
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <PaymentForm></PaymentForm>
          <Divider className="mb-16"></Divider>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CheckboxForm color="primary" name="hasReceipt" label="¿Agregar comprobante?"></CheckboxForm>
            </Grid>
            {hasReceipt ? (
              <Grid xs={6} item>
                {!client?.rfc ? (
                  <Typography variant="body1" color="error">
                    El cliente seleccionado debe tener RFC registrado
                  </Typography>
                ) : (
                  <SelectForm
                    name="receiptType"
                    label="Tipo de comprobante"
                    variant="outlined"
                    className="mb-16"
                    required
                    fullWidth
                    disabled={loading}
                  >
                    <MenuItem value="none">
                      <em>Seleccionar tipo de comprobante</em>
                    </MenuItem>
                    <MenuItem value="invoice">Factura</MenuItem>
                    <MenuItem value="ticket">Nota de cargo</MenuItem>
                  </SelectForm>
                )}
              </Grid>
            ) : null}
          </Grid>
          {receiptType !== 'none' ? renderReceiptForm() : null}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function CustomerCreditForm() {
  const { getValues, control, setValue } = useFormContext()

  const paymentMethod = useWatch<PaymentMethod>({
    control,
    name: 'paymentMethod',
    defaultValue: getValues('paymentMethod'),
  })

  const client = useWatch<Company>({
    control,
    name: 'client',
    defaultValue: getValues('client'),
  })

  React.useEffect(() => {
    setValue('customerCredit', null)
  }, [client, setValue])

  const isDisabled = !client

  return paymentMethod === PaymentMethod.customer_credit ? (
    <CreditAutocomplete
      name="customerCredit"
      kind={[CreditKind.customer]}
      filterOptions={options => options.filter(opt => opt.company.id === client?.id)}
      inputProps={{ label: 'Crédito de cliente', variant: 'outlined', required: true }}
      disabled={isDisabled}
      placeholder="Buscar crédito"
      className="mb-16"
      fullWidth
    ></CreditAutocomplete>
  ) : null
}

function BankAccountForm() {
  const { getValues, control } = useFormContext()

  const paymentMethod = useWatch<PaymentMethod>({
    control,
    name: 'paymentMethod',
    defaultValue: getValues('paymentMethod'),
  })

  return !nonBankPaymentMethods.includes(paymentMethod) ? (
    <BankAccountAutocomplete
      name="bankAccount"
      inputProps={{ label: 'Cuenta de banco', variant: 'outlined', required: true }}
      placeholder="Buscar cuenta de banco"
      className="mb-16"
      fullWidth
    ></BankAccountAutocomplete>
  ) : null
}

function useIsPayment() {
  const { getValues, control } = useFormContext()

  const isPayment = useWatch<CreateForm['isPayment']>({
    control,
    name: 'isPayment',
    defaultValue: getValues('isPayment'),
  })

  return isPayment
}

function usePaymentCredit() {
  const { getValues, control } = useFormContext()

  const paymentCredit = useWatch<CreateForm['paymentCredit']>({
    control,
    name: 'paymentCredit',
    defaultValue: getValues('paymentCredit'),
  })

  return paymentCredit
}

function ClientForm() {
  const { setValue } = useFormContext()
  const paymentCredit = usePaymentCredit()
  const isPayment = useIsPayment()

  React.useEffect(() => {
    setValue('client', paymentCredit?.company ?? null)
  }, [paymentCredit, setValue])

  return (
    <CompanyAutocomplete
      name="client"
      type={[CompanyType.customer]}
      inputProps={{ label: 'Cliente', variant: 'outlined', required: true }}
      placeholder="Buscar cliente"
      className="mb-16"
      disabled={!!isPayment}
      fullWidth
    ></CompanyAutocomplete>
  )
}

function OrganizationForm() {
  const { setValue } = useFormContext()
  const paymentCredit = usePaymentCredit()
  const isPayment = useIsPayment()

  React.useEffect(() => {
    setValue('organization', paymentCredit?.organization ?? null)
  }, [paymentCredit, setValue])

  return (
    <OrganizationAutocomplete
      name="organization"
      inputProps={{ label: 'Organización', variant: 'outlined', required: true }}
      placeholder="Buscar organización"
      className="mb-16"
      disabled={!!isPayment}
      fullWidth
    ></OrganizationAutocomplete>
  )
}

function CreditToPayForm() {
  const isPayment = useIsPayment()

  return isPayment ? (
    <CreditAutocomplete
      kind={[CreditKind.customer]}
      name="paymentCredit"
      inputProps={{ label: 'Crédito a pagar', variant: 'outlined', required: true }}
      placeholder="Buscar el crédito"
      className="mb-16"
      fullWidth
    ></CreditAutocomplete>
  ) : null
}

function PaymentForm() {
  const isPayment = useIsPayment()
  return isPayment ? (
    <>
      <Divider className="mb-16"></Divider>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <TextFieldForm
            TextComponent={CurrencyTextField}
            name="capital"
            label="A Capital"
            placeholder="Ingresar monto para capital"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <TextFieldForm
            TextComponent={CurrencyTextField}
            name="interest"
            label="Interés"
            placeholder="Ingresar monto de interés"
            variant="outlined"
            className="mb-16"
            fullWidth
          />
        </Grid>
        <Grid xs={6} item>
          <TextFieldForm
            TextComponent={CurrencyTextField}
            name="otherAmount"
            label="Otros cargos"
            placeholder="Ingresar otros cargos"
            variant="outlined"
            className="mb-16"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  ) : null
}
