import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useTypeCreditList } from '../data/Queries'
import { TypeCredit } from '../models/TypeCredit'
import { Column, Row } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { StatusUpdate } from './StatusUpdate'
import { Delete } from './Delete'
import { Card } from '@material-ui/core'
import { DeleteButton } from 'shared/crud/DeleteButton'
import { ExportCsvButton, ExportCsvButtonProps } from 'shared/crud/ExportCsvButton'
import { getRowsForDeletion } from 'shared/utils'

export const List = () => {
  const { data: typeCreditListData } = useTypeCreditList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const columns = React.useMemo<Column<TypeCredit>[]>(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Estatus',
        accessor: typeCredit => <StatusUpdate typeCredit={typeCredit}></StatusUpdate>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: typeCredit => (
          <EditButton title="Editar usuario" aria-label="editar usuario" id={typeCredit.id} tenantId={typeCredit.tenantId}></EditButton>
        ),
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return typeCreditListData?.listTypeCredit ?? []
  }, [typeCreditListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<TypeCredit>>(() => {
    return {
      columns: [
        {
          id: 'name',
          header: 'Nombre',
          cell: row => row.name,
        },
        {
          id: 'createdAt',
          header: 'Fecha de creación',
          cell: row => dayjs(row.createdAt).format('L'),
        },
      ],
      filename: `Tipos de crédito - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data])

  const toolBarContent = (selectedRows: Row<TypeCredit>[]) => {
    return selectedRows.length ? (
      <DeleteButton>
        <Delete typeCredits={getRowsForDeletion(selectedRows)}></Delete>
      </DeleteButton>
    ) : (
      <ExportCsvButton {...csvProps}></ExportCsvButton>
    )
  }

  return (
    <Card>
      <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
    </Card>
  )
}
