import { useHasPermission } from 'auth/hooks/UseHasPermission'
import * as React from 'react'

export interface WithPermissionsProps {
  permissions: string[]
  rejected?: () => React.ReactNode
}

export const WithPermissions: React.FC<WithPermissionsProps> = ({ rejected, permissions, children }) => {
  const hasPermission = useHasPermission(permissions)

  return <>{hasPermission ? children : rejected?.() ?? null}</>
}
