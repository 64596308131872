import { useMutation, gql } from '@apollo/client'
import { UserProfile } from 'auth/models/UserProfile'
import { UserProfileFragment } from './Queries'

export interface UpdateUserProfilePayload {
  id: string
  name: string
  label: string
  permissions?: string[]
}

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($data: UpdateUserProfilePayload!) {
    updateUserProfile(data: $data) {
      ...UserProfileFragment
    }
  }
  ${UserProfileFragment}
`

export const useUpdateUserProfileMutation = () => {
  const tuple = useMutation<{ updateUserProfile: UserProfile }, { data: UpdateUserProfilePayload }>(UPDATE_USER_PROFILE)

  return tuple
}

export interface RegisterUserProfilePayload {
  name: string
  label: string
  permissions?: string[]
}

const REGISTER_USER_PROFILE = gql`
  mutation CreateUserProfile($data: RegisterUserProfilePayload!) {
    registerUserProfile(data: $data) {
      ...UserProfileFragment
    }
  }
  ${UserProfileFragment}
`

export const useRegisterUserProfileMutation = () => {
  const tuple = useMutation<{ registerUserProfile: UserProfile }, { data: RegisterUserProfilePayload }>(REGISTER_USER_PROFILE)

  return tuple
}
