import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { CreditCard } from '../models/CreditCard'

const LIST_CREDIT_CARD = gql`
  query {
    listCreditCard {
      id
      accountNumber
      name
      ownerName
      account {
        id
        initialBalance
        balance
      }
      bank {
        id
        name
        status
      }
      endDay
      limitDay
      creditLimit
      status
      createdAt
      tenantId
    }
  }
`
export type ListCreditCardResponse = { listCreditCard: CreditCard[] }

export const useCreditCardList = (options?: QueryHookOptions<ListCreditCardResponse>) => {
  const result = useQuery<ListCreditCardResponse>(LIST_CREDIT_CARD, options)
  return result
}

const GET_CREDIT_CARD = gql`
  query GetCreditCard($id: String!) {
    getCreditCard(id: $id) {
      id
      accountNumber
      name
      ownerName
      bank {
        id
        name
        status
      }
      endDay
      limitDay
      creditLimit
      status
      tenantId
    }
  }
`
export type GetCreditCardResponse = { getCreditCard: CreditCard }
export interface GetCreditCardVars {
  id: string
}

export const useGetCreditCard = (options?: QueryHookOptions<GetCreditCardResponse, GetCreditCardVars>) => {
  const result = useQuery<GetCreditCardResponse, GetCreditCardVars>(GET_CREDIT_CARD, options)
  return result
}
