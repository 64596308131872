import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Outcome, outcomePaymentMethodsLabels, OutcomeType, outcomeTypes, PaymentMethod } from '../models/Outcome'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteOutcomeMutation } from '../data/Mutations'
import { formatCurrency } from 'shared/utils'
import dayjs from 'dayjs'

export interface DeleteProps {
  outcome: Outcome
}

const dialogId = 'OutcomeDeleteDialog'

function getPaymentMethodName(term: PaymentMethod) {
  return outcomePaymentMethodsLabels[term]
}
function getOutcomeType(term: OutcomeType) {
  return outcomeTypes.find(pm => pm.value === term)?.text
}

export const Delete = ({ outcome }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteOutcome] = useDeleteOutcomeMutation()

  const deleteOutcomes = async () => {
    try {
      await deleteOutcome({ variables: { id: outcome.id } })
      enqueueSnackbar(`Egreso eliminado correctamente`, { variant: 'success' })
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(`Ha ocurrido un error al eliminar el egreso. Intentelo más tarde`, { variant: 'error' })
    }
  }

  return (
    <>
      <Tooltip title="Eliminar egreso">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onClose={close} aria-labelledby={dialogId}>
        <DialogTitle id={dialogId}>Eliminar egreso</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Deseas eliminar el egreso con los siguientes datos?</DialogContentText>
          <List dense>
            <ListItem>
              <ListItemText primary={`- Fecha: ${dayjs(outcome.date).format('L')}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`- Tipo de egreso: ${getOutcomeType(outcome.outcomeType)}`} />
            </ListItem>
            {outcome.organization ? (
              <ListItem>
                <ListItemText primary={`- Organización: ${outcome.organization.name}`} />{' '}
              </ListItem>
            ) : null}
            {outcome.company ? (
              <ListItem>
                <ListItemText primary={`- Proveedor/Empleado: ${outcome.company.name}`} />
              </ListItem>
            ) : null}
            {outcome.category ? (
              <ListItem>
                <ListItemText primary={`- Categoría: ${outcome.category.name}`} />{' '}
              </ListItem>
            ) : null}
            {outcome.accountPayment ? (
              <ListItem>
                <ListItemText
                  primary={`- Cuenta de abono: ${outcome.accountPayment?.credit?.name || outcome.accountPayment?.creditCard?.name}`}
                />{' '}
              </ListItem>
            ) : null}
            {outcome.bankAccount ? (
              <ListItem>
                <ListItemText primary={`- Cuenta de cargo: ${outcome.bankAccount.name}`} />
              </ListItem>
            ) : null}
            {outcome.creditCard ? (
              <ListItem>
                <ListItemText primary={`- Cuenta de cargo (TC): ${outcome.creditCard.name}`} />
              </ListItem>
            ) : null}
            {outcome.credit ? (
              <ListItem>
                <ListItemText primary={`- Crédito (proveedores): ${outcome.credit.name}`} />
              </ListItem>
            ) : null}
            <ListItem>
              <ListItemText primary={`- Monto: ${formatCurrency(outcome.amount)}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`- Método de pago: ${getPaymentMethodName(outcome.paymentMethod)}`} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteOutcomes} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
