import * as React from 'react'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import MaskIcon from '@material-ui/icons/Visibility'
import UnmaskIcon from '@material-ui/icons/VisibilityOff'

export const PasswordTextField = (props: TextFieldProps) => {
  const [masked, setMasked] = React.useState(true)

  const type = masked ? 'password' : 'text'

  const Icon = !masked ? UnmaskIcon : MaskIcon

  const toggleMask = () => {
    setMasked(prevVal => !prevVal)
  }

  return (
    <TextField
      type={type}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={toggleMask}>
              <Icon></Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    ></TextField>
  )
}
