import * as React from 'react'
import clsx from 'clsx'
import { ListSubheader, makeStyles, Theme } from '@material-ui/core'
import { NavProps } from './types'
import { NavigationGroup } from '../types'
import { useNavigationElement } from '../hooks/useNavigationElement'
import { NavigationItemWrapper } from './NavigationItemWrapper'

const useStyles = makeStyles<Theme, { itemPadding: number }>(theme => ({
  item: props => ({
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingRight: 12,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    '&.active > .list-subheader-text': {
      fontWeight: 700,
    },
  }),
}))

export const NavGroup: React.FC<NavProps<NavigationGroup>> = props => {
  const { item, nestedLevel } = props

  const { closeNavbar, itemPadding } = useNavigationElement(nestedLevel)

  const classes = useStyles({
    itemPadding,
  })

  return (
    <>
      <ListSubheader
        disableSticky
        className={clsx(classes.item, 'list-subheader flex items-center cursor-default')}
        onClick={closeNavbar}
        component="li"
        role="button"
      >
        <span className="list-subheader-text uppercase text-12">{item.title}</span>
      </ListSubheader>

      {item.children && (
        <>
          {item.children.map(_item => (
            <NavigationItemWrapper key={_item.id} item={_item} nestedLevel={nestedLevel} />
          ))}
        </>
      )}
    </>
  )
}
