import { UserProfile } from 'auth/models/UserProfile'
import * as React from 'react'
import { TransferList } from 'shared/common/TransferList/TransferList'
import { useProfilesList } from '../data/Queries'

export interface ProfilesTransferListProps {
  initialValue?: UserProfile[]
  onSelectionChanged: (selected: UserProfile[]) => void
}

export const ProfilesTransferList = ({ initialValue, onSelectionChanged }: ProfilesTransferListProps) => {
  const { loading, data } = useProfilesList({
    fetchPolicy: 'network-only',
  })

  const options = React.useMemo(() => {
    return data?.listUserProfiles ?? []
  }, [data])

  return loading || !options.length ? (
    <>loading</>
  ) : (
    <TransferList
      initialValue={initialValue && options.filter(option => initialValue.find(iv => iv.id === option.id))}
      options={options}
      getOptionLabel={item => item.label}
      onChosenChange={onSelectionChanged}
    ></TransferList>
  )
}
