import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Company, CompanyType } from '../models/Company'

export type ListCompanyResponse = { listCompany: Company[] }

export interface ListCompanyByTypePayload {
  companyType?: CompanyType[]
}

const LIST_COMPANY = gql`
  query ListCompany($data: ListCompanyByTypePayload!) {
    listCompany(data: $data) {
      id
      name
      rfc
      businessName
      email
      phone
      companyType
      status
      createdAt
      tenantId
    }
  }
`

export const useCompanyList = (options?: QueryHookOptions<ListCompanyResponse, { data: ListCompanyByTypePayload }>) => {
  const result = useQuery<ListCompanyResponse, { data: ListCompanyByTypePayload }>(LIST_COMPANY, options)
  return result
}

const GET_COMPANY = gql`
  query GetCompany($id: String!) {
    getCompany(id: $id) {
      id
      name
      rfc
      businessName
      email
      contact
      description
      phone
      companyType
      status
      tenantId
    }
  }
`
export type GetCompanyResponse = { getCompany: Company }
export interface GetCompanyVars {
  id: string
}

export const useGetCompany = (options?: QueryHookOptions<GetCompanyResponse, GetCompanyVars>) => {
  const result = useQuery<GetCompanyResponse, GetCompanyVars>(GET_COMPANY, options)
  return result
}
