import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { CheckboxForm, Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useRegisterCreditCardMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { generateNumberList } from 'core/utils'
import { BankSelect } from 'modules/bank/components/BankSelect'
import { MoveType } from 'modules/category-move/models/CategoryMove'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'

interface CreateForm {
  accountNumber: string
  name: string
  ownerName: string
  bankId: string
  endDay: number
  limitDay: number
  creditLimit: number
  balance: number
  isPositiveBalance: boolean
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la tarjeta es un campo requerido'),
  // eslint-disable-next-line no-template-curly-in-string
  accountNumber: yup.string().min(4, 'El mínimo de caracteres es ${min}').required('Los últimos 4 digitos son un campo requerido'),
  ownerName: yup.string(),
  // eslint-disable-next-line no-template-curly-in-string
  endDay: yup.number().max(31, 'El día de corte debe ser menor a ${max}').required('El día de corte es un campo requerido'),
  // eslint-disable-next-line no-template-curly-in-string
  limitDay: yup.number().max(31, 'El día límite de pago debe ser menor a ${max}').required('El día límite de pago es un campo requerido'),
  creditLimit: yup.number().required('El límite de crédito es un campo requerido'),
  bankId: yup.string().required('El banco es un campo requerido'),
  balance: yup.number(),
})

const dialogId = 'cards/create-dialog'
const formId = 'cards/create-form'

const days = generateNumberList(31).map(i => i + 1)

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const [addCreditCard, { loading }] = useRegisterCreditCardMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: '',
      name: '',
      ownerName: '',
      bankId: '',
      balance: 0,
      endDay: 1,
      limitDay: 15,
      creditLimit: 1000,
      isPositiveBalance: false,
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { isPositiveBalance, ...creditCardData } = values

      await addCreditCard({
        variables: { data: { ...creditCardData, moveType: !isPositiveBalance ? MoveType.outcome : MoveType.income } },
      })

      enqueueSnackbar('Tarjeta registrada correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al registrar la tarjeta. Intentelo más tarde', { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/creditcards')
  }

  return (
    <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Registrar tarjeta</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <Grid container spacing={2}>
            <Grid xs={8} item>
              <TextFieldForm
                name="name"
                label="Nombre de la tarjeta"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={4} item>
              <TextFieldForm
                name="accountNumber"
                label="Últimos 4 digitos"
                placeholder="Ingresar últimos 4 digitos"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                transform={{
                  output: ev => (/^[0-9]+$/.test(ev.target.value) ? ev.target.value : ev.target.value.slice(0, -1)),
                }}
                inputProps={{
                  maxLength: 4,
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={8} item>
              <TextFieldForm
                name="ownerName"
                label="Propietario"
                placeholder="Ingresar propietario"
                variant="outlined"
                className="mb-16"
                fullWidth
                disabled={loading}
              />
            </Grid>

            <Grid xs={4} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="creditLimit"
                label="Límite de crédito"
                placeholder="Ingresar límite de crédito"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid xs={6} item>
              <BankSelect name="bankId" disabled={loading}></BankSelect>
            </Grid>
            <Grid xs={3} item>
              <SelectForm name="endDay" label="Día de corte" variant="outlined" className="mb-16" required fullWidth disabled={loading}>
                {days.map(day => {
                  return (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
            <Grid xs={3} item>
              <SelectForm
                name="limitDay"
                label="Día límite de pago"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {days.map(day => {
                  return (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={4} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="balance"
                label="Saldo inicial"
                placeholder="Ingresar saldo"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={8} item>
              <CheckboxForm color="primary" name="isPositiveBalance" label="¿Es saldo a favor?"></CheckboxForm>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
