import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, TextFieldForm } from 'shared/forms'
import { useRegisterUserMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { PasswordTextField } from 'shared/common/PasswordTextField'
import { ProfilesTransferList } from './ProfilesTransferList'
import { UserProfile } from 'auth/models/UserProfile'
import { PermissionsTransferList } from './PermissionsTransferList'
import { Permission } from 'auth/models/Permission'

interface CreateForm {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirm: string
}

const useStyles = makeStyles({
  form: {
    width: 800,
    margin: '0 auto',
  },
})

const schema = yup.object().shape({
  firstName: yup.string().required('El nombre es un campo requerido'),
  lastName: yup.string().required('El apellido es un campo requerido'),
  email: yup.string().email('El formato del correo electrónico no es válido').required('El correo electrónico es un campo requerido'),
  // eslint-disable-next-line no-template-curly-in-string
  password: yup.string().min(8, 'La contraseña debe contener mínimo ${min} caracteres').required('La contraseña es un campo requerido'),
  passwordConfirm: yup.string().oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
})

const dialogId = 'users/create-dialog'
const formId = 'users/create-form'

export const Create = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(true)

  const [profiles, setProfiles] = React.useState<UserProfile[]>([])
  const [permissions, setPermissions] = React.useState<Permission[]>([])

  const [addUser, { loading }] = useRegisterUserMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
  })

  const { formState, trigger, getValues } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { passwordConfirm, ...data } = values

      await addUser({
        variables: {
          data: {
            ...data,
            profiles: profiles.map(profile => profile.id),
            permissions: permissions.map(permission => permission.id),
          },
        },
      })

      enqueueSnackbar('Usuario registrado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al registrar el usuario. Intentelo más tarde', { variant: 'error' })
    }
  }

  const onPasswordChange = () => {
    return getValues('passwordConfirm') && trigger('passwordConfirm')
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/users')
  }

  return (
    <Dialog fullWidth maxWidth="md" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Registrar usuario</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId, className: classes.form }} onSubmit={onSubmit} {...formInstance}>
          <h3>Datos generales</h3>
          <Divider className="mt-4 mb-16" />
          <TextFieldForm
            name="email"
            label="Correo electrónico"
            placeholder="Ingresar correo electrónico"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="firstName"
                label="Nombre"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                name="lastName"
                label="Apellidos"
                placeholder="Ingresar apellidos"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={PasswordTextField}
                name="password"
                label="Contraseña"
                placeholder="Ingresar contraseña"
                variant="outlined"
                className="mb-16"
                onChange={onPasswordChange}
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={PasswordTextField}
                name="passwordConfirm"
                label="Confirmar contraseña"
                placeholder="Ingresar confirmación"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <h3 className="mt-8">Asignar perfil</h3>
          <Divider className="mt-4 mb-16" />
          <ProfilesTransferList onSelectionChanged={setProfiles}></ProfilesTransferList>
          <h3 className="mt-8">Asignar permisos</h3>
          <Divider className="mt-4 mb-16" />
          <PermissionsTransferList onSelectionChanged={setPermissions}></PermissionsTransferList>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
