import * as React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Form, TextFieldForm } from 'shared/forms'
import { useUpdateCustomerCreditMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetCredit } from 'modules/credit/data/Queries'
import { Company, CompanyType } from 'modules/company/models/Company'
import { Organization } from 'modules/organization/models/Organization'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'

interface UpdateForm {
  name: string
  organization: Organization
  company: Company
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
  organization: yup.object().nullable().required('Debe asignar una organización al crédito'),
  company: yup.object().nullable().required('Debe asignar un acreedor al crédito'),
})

const formId = 'UpdateCredit'
const dialogId = 'CreditEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetCredit({ variables: { id }, fetchPolicy: 'cache-and-network' })
  const [updateCredit, { loading: isUpdating }] = useUpdateCustomerCreditMutation()

  const loading = isGetting || isUpdating

  const credit = React.useMemo(() => {
    return data?.getCredit
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      organization: null,
      company: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (credit) {
      reset(credit)
    }
  }, [credit, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener el tipo de crédito. Intentelo más tarde'

      if (error.message === 'Credit not found') {
        msg = 'Tipo de crédito no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/customer-credits')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { company, organization, ...data } = values

      await updateCredit({
        variables: {
          data: {
            id: credit.id,
            customerId: company?.id,
            organizationId: organization?.id,
            ...data,
          },
        },
      })

      enqueueSnackbar('Crédito actualizado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al actualizar el crédito. Intentelo más tarde', { variant: 'error' })
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar tipo de crédito</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <TextFieldForm
                name="name"
                label="Nombre del crédito"
                placeholder="Ingresar nombre del crédito"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <OrganizationAutocomplete
                name="organization"
                inputProps={{ label: 'Organización', variant: 'outlined', required: true }}
                placeholder="Buscar organización"
                className="mb-16"
                fullWidth
              ></OrganizationAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <CompanyAutocomplete
                name="company"
                type={[CompanyType.customer]}
                inputProps={{ label: 'Cliente', variant: 'outlined', required: true }}
                placeholder="Buscar Cliente"
                className="mb-16"
                fullWidth
              ></CompanyAutocomplete>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
