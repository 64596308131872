import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useSATPaymentMethodList } from '../data/Queries'
import { SATPaymentMethod } from '../models/SATPaymentMethod'

export interface SATPaymentMethodAutocompleteProps<Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>
  extends Omit<AutocompleteFormProps<SATPaymentMethod, Multiple, DisableClearable, FreeSolo>, 'options' | 'loading'> {
  onOptionsLoaded?: (options: SATPaymentMethod[]) => void
}

export const SATPaymentMethodAutocomplete = <Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>({
  getOptionLabel = option => `${option.code} - ${option.name}`,
  getOptionSelected = (option, value) => option.id === value.id,
  onOptionsLoaded,
  ...props
}: SATPaymentMethodAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useSATPaymentMethodList({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onOptionsLoaded?.(data.listSATPaymentMethod)
    },
  })

  const options = React.useMemo(() => {
    return data?.listSATPaymentMethod ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}
