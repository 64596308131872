import * as React from 'react'
import { createStyles, lighten, makeStyles, Toolbar, Typography, TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'

const useToolbarStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.text.primary,
      backgroundColor: lighten(theme.palette.secondary.dark, 0.5),
    },
    title: {
      flex: '1 1 100%',
    },
    search: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '27px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 14px',
      },
      '& .MuiInputAdornment-positionStart': {
        margin: 0,
      },
    },
  }),
)

export interface TableToolbarProps {
  selectedCount: number
  count: number
  globalFilter: string
  setGlobalFilter: (newVal: string) => void
}

export const TableToolbar: React.FC<TableToolbarProps> = ({ selectedCount, count, globalFilter, setGlobalFilter, children }) => {
  const classes = useToolbarStyles()

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selectedCount > 0,
      })}
    >
      {selectedCount > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {selectedCount} seleccionado(s)
        </Typography>
      ) : (
        <Typography variant="subtitle1" className={classes.title}>
          Total de {count} registros
        </Typography>
      )}
      {selectedCount === 0 ? (
        <div className="flex flex-row items-center px-10">
          <TextField
            className={classes.search}
            placeholder="Buscar"
            variant="outlined"
            value={globalFilter}
            onChange={ev => setGlobalFilter(ev.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </div>
      ) : null}
      {children}
    </Toolbar>
  )
}
