import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form, TextFieldForm } from 'shared/forms'
import { useUpdateOrganizationMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetOrganization } from '../data/Queries'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { WithPermissions } from 'auth/components/WithPermissions'

interface UpdateForm {
  name: string
  rfc?: string
  businessName: string
  initialBalance?: number
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la organización es un campo requerido'),
  // eslint-disable-next-line no-template-curly-in-string
  rfc: yup.string().min(12, 'El mínimo de caracteres es ${min}').isOptional(),
  businessName: yup.string().required('La razón social de la organización es un campo requerido'),
  initialBalance: yup.number().min(0, 'El saldo inicial no puede ser negativo').nullable(),
})

const formId = 'UpdateOrganization'
const dialogId = 'OrganizationEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetOrganization({ variables: { id }, fetchPolicy: 'cache-and-network' })
  const [updateOrganization, { loading: isUpdating }] = useUpdateOrganizationMutation()

  const loading = isGetting || isUpdating

  const organization = React.useMemo(() => {
    return data?.getOrganization
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      rfc: '',
      businessName: '',
      initialBalance: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (organization) {
      reset({
        name: organization.name,
        rfc: organization.rfc,
        businessName: organization.businessName,
        initialBalance: null,
      })
    }
  }, [organization, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener la organización. Intentelo más tarde'

      if (error.message === 'Organization not found') {
        msg = 'Organización no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/organizations')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await updateOrganization({
        variables: { data: { id: organization.id, ...values } },
      })

      enqueueSnackbar('Organización actualizada correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al actualizar la organización. Intentelo más tarde', { variant: 'error' })
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Editar organización</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="name"
                label="Nombre"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                name="rfc"
                label="RFC"
                placeholder="Ingresar rfc"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                transform={{
                  output: ev => ev.target.value.toUpperCase(),
                }}
                inputProps={{
                  maxLength: 13,
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <WithPermissions permissions={[]}>
                <TextFieldForm
                  TextComponent={CurrencyTextField}
                  name="initialBalance"
                  label="Saldo inicial"
                  placeholder="Ingresar saldo inicial"
                  variant="outlined"
                  className="mb-16"
                  fullWidth
                  disabled={loading}
                />
              </WithPermissions>
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                name="businessName"
                label="Razón social"
                placeholder="Ingresar razón social"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
