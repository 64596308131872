import * as React from 'react'
import { Collapse, Icon, IconButton, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core'
import { NavProps } from './types'
import { NavigationCollapse, NavigationCollapseElement } from 'navigation/types'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'
import clsx from 'clsx'
import { NavigationBadge } from 'navigation/NavigationBadge'
import { NavigationItemWrapper } from './NavigationItemWrapper'

const useStyles = makeStyles<Theme, { itemPadding: number }>(theme => ({
  root: {
    padding: 0,
    '&.open': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255,255,255,.015)' : 'rgba(0,0,0,.025)',
    },
  },
  item: props => ({
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingRight: 12,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    color: theme.palette.text.primary,
    '&.active > .list-item-text > span': {
      fontWeight: 600,
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
  }),
}))

function needsToBeOpened<T>(location: Location<T>, item: NavigationCollapse) {
  return location && isUrlInChildren(item, location.pathname)
}

function isUrlInChildren(parent: NavigationCollapseElement, url: string) {
  if (parent.type !== 'collapse') {
    return false
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    const item = parent.children[i]
    if (item.type === 'collapse') {
      if (isUrlInChildren(item, url)) {
        return true
      }
    } else {
      if (item.url === url || url.includes(item.url)) {
        return true
      }
    }
  }

  return false
}

export function NavCollapse(props: NavProps<NavigationCollapse>) {
  const location = useLocation()

  const [open, setOpen] = React.useState<boolean>(() => needsToBeOpened(location, props.item))
  const { item, nestedLevel } = props
  const classes = useStyles({
    itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24,
  })

  React.useEffect(() => {
    if (needsToBeOpened(location, props.item)) {
      if (!open) {
        setOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, props.item])

  function handleClick() {
    setOpen(prevState => !prevState)
  }

  return (
    <ul className={clsx(classes.root, open && 'open')}>
      <ListItem button className={clsx(classes.item, 'list-item')} onClick={handleClick} component="li" role="button">
        {item.icon && (
          <Icon color="action" className="list-item-icon text-16 flex-shrink-0">
            {item.icon}
          </Icon>
        )}

        <ListItemText className="list-item-text" primary={item.title} classes={{ primary: 'text-14' }} />

        {item.badge && <NavigationBadge className="mx-4" badge={item.badge} />}

        <IconButton
          disableRipple
          className="w-40 h-40 -mx-12 p-0 focus:bg-transparent hover:bg-transparent"
          onClick={ev => ev.preventDefault()}
        >
          <Icon className="text-16 arrow-icon" color="inherit">
            {open ? 'expand_less' : 'expand_more'}
          </Icon>
        </IconButton>
      </ListItem>

      {item.children && (
        <Collapse in={open} className="collapse-children">
          {item.children.map(_item => (
            <NavigationItemWrapper key={_item.id} item={_item} nestedLevel={nestedLevel + 1} />
          ))}
        </Collapse>
      )}
    </ul>
  )
}
