import * as React from 'react'
import { User } from 'auth/models/User'
import { createReducerContext } from 'shared/utils/ReactContext'
import { AuthRepository } from 'auth/data/AuthRepository'
import { fetchCurrentUser } from 'auth/data/AuthQueries'

export interface AuthState {
  loading: boolean
  user: User | null
}

export type AuthActions = { type: 'setLoading' } | { type: 'setUser'; payload: User } | { type: 'logout' }

const authReducer: React.Reducer<AuthState, AuthActions> = (_state, action) => {
  switch (action.type) {
    case 'setLoading':
      return { loading: true, user: null }
    case 'setUser':
      return { loading: false, user: action.payload }
    case 'logout':
      return { loading: false, user: null }
    default:
      throw new Error('No valid action to AuthReducer')
  }
}

const initialState: AuthState = {
  loading: true,
  user: null,
}

const [Provider, useAuthContext, useAuthDispatch] = createReducerContext(authReducer, initialState, 'AuthContext')

const AuthChecker: React.FC = ({ children }) => {
  const dispatch = useAuthDispatch()

  React.useEffect(() => {
    const token = AuthRepository.getToken()

    const logout = () => {
      dispatch({ type: 'logout' })
      AuthRepository.clearSession()
    }

    if (!token) {
      logout()
      return
    }

    dispatch({ type: 'setLoading' })

    fetchCurrentUser()
      .then(user => {
        AuthRepository.setTenant(user.tenants[0]?.id)
        dispatch({ type: 'setUser', payload: user })
      })
      .catch(err => {
        console.warn(err)
        logout()
      })
  }, [dispatch])

  return <>{children}</>
}

const AuthProvider: React.FC = ({ children }) => {
  return (
    <Provider>
      <AuthChecker>{children}</AuthChecker>
    </Provider>
  )
}

export { AuthProvider, useAuthContext, useAuthDispatch }
