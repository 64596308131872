import { Checkbox, ListItemText } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import { CompanyType } from '../models/Company'

export const CompanyTypeItem = ({ value, text }: { value: CompanyType; text: string }) => {
  const { control, getValues } = useFormContext()

  const companyTypes = useWatch({
    control,
    name: 'companyType',
    defaultValue: getValues('companyType'),
  })

  return (
    <>
      <Checkbox color="primary" checked={companyTypes.indexOf(value) > -1}></Checkbox>
      <ListItemText primary={text}></ListItemText>
    </>
  )
}
