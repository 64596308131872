import * as React from 'react'
import ReactApexChart from 'react-apexcharts'
import { CircularProgress, Paper, Select, Typography, useTheme } from '@material-ui/core'
import { useExpenseCategoriesTotals } from '../data/Queries'
import { FilterType, getLimits } from '../utils'
import { formatCurrency } from 'shared/utils'

const options: ApexCharts.ApexOptions = {
  chart: {
    height: '100%',
    type: 'pie',
  },
  stroke: {
    width: 1,
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: 'bottom',
  },
  theme: {
    monochrome: {
      enabled: true,
      shadeTo: 'light',
      shadeIntensity: 0.65,
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return formatCurrency(value)
      },
    },
  },
}

const defaultFilter: FilterType = 'monthly'

export const ExpenseCategories = () => {
  const theme = useTheme()
  const [getTotals, { loading, error, data }] = useExpenseCategoriesTotals({
    pollInterval: 10000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const chart = React.useMemo(() => {
    if (!data?.getCategoryOutcome) {
      return {
        labels: [],
        data: [],
      }
    }

    return {
      labels: data?.getCategoryOutcome.map(r => (r.name === 'other' ? 'Otras categorías' : r.name)),
      data: data?.getCategoryOutcome.map(r => r.amount),
    }
  }, [data])

  React.useEffect(() => {
    const data = getLimits(defaultFilter)
    getTotals({
      variables: {
        data,
      },
    })
  }, [getTotals])

  const onFilter = (ev: React.ChangeEvent<{ name?: string; value: unknown }>, _child: React.ReactNode) => {
    const value = ev.target.value as FilterType

    const data = getLimits(value)
    getTotals({
      variables: {
        data,
      },
    })
  }

  const renderTotals = () => {
    if (loading) {
      return (
        <Paper className="h-full flex items-center justify-center">
          <CircularProgress></CircularProgress>
        </Paper>
      )
    }

    if (error) {
      return (
        <Paper className="h-full flex items-center justify-center">
          <Typography variant="h6" color="error">
            Error al intentar obtener la información. Intente de nuevo más tarde.
          </Typography>
        </Paper>
      )
    }

    return (
      <>
        <div className="h-400 w-full">
          <ReactApexChart
            options={{
              ...options,
              labels: chart.labels,
              theme: {
                monochrome: {
                  ...options.theme.monochrome,
                  color: theme.palette.primary.main,
                },
              },
            }}
            series={chart.data}
            type={options.chart.type}
            height={options.chart.height}
          />
        </div>
      </>
    )
  }

  return (
    <Paper className="h-full">
      <div className="flex items-center justify-between p-20 h-64">
        <Typography className="text-16 font-medium">Gasto</Typography>
        <Select
          native
          onChange={onFilter}
          defaultValue={defaultFilter}
          inputProps={{
            name: 'outcome-filter',
          }}
          className="font-medium opacity-75"
          disabled={loading}
          disableUnderline
        >
          <option value="monthly">Último Mes</option>
          <option value="annual">Año</option>
        </Select>
      </div>
      {renderTotals()}
    </Paper>
  )
}
