import { useMutation, gql } from '@apollo/client'
import { User } from 'auth/models/User'
import { Status } from 'models/BaseModel'

export interface ChangeUserStatusPayload {
  id: string
  status: Status
}

const CHANGE_USER_STATUS = gql`
  mutation ChangeUserStatus($data: ChangeUserStatusPayload!) {
    changeUserStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeUserStatusMutation = () => {
  const tuple = useMutation<{ changeUserStatus: User }, { data: ChangeUserStatusPayload }>(CHANGE_USER_STATUS)

  return tuple
}

export interface UpdateUserPayload {
  id: string
  firstName: string
  lastName: string
  profiles?: string[]
  permissions?: string[]
}

const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserPayload!) {
    updateUser(data: $data) {
      id
      firstName
      lastName
    }
  }
`

export const useUpdateUserMutation = () => {
  const tuple = useMutation<{ updateUser: User }, { data: UpdateUserPayload }>(UPDATE_USER)

  return tuple
}

const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      status
    }
  }
`

export const useDeleteUserMutation = () => {
  const tuple = useMutation<{ deleteUser: User }, { id: string }>(DELETE_USER)

  return tuple
}

export interface RegisterUserPayload {
  firstName: string
  lastName: string
  email: string
  password: string
  profiles?: string[]
  permissions?: string[]
}

const REGISTER_USER = gql`
  mutation CreateUser($data: RegisterUserPayload!) {
    registerUser(data: $data) {
      id
      email
      firstName
      lastName
      createdAt
      status
    }
  }
`

export const useRegisterUserMutation = () => {
  const tuple = useMutation<{ registerUser: User }, { data: RegisterUserPayload }>(REGISTER_USER)

  return tuple
}

export interface ChangeUserPasswordPayload {
  id: string
  password: string
}

const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword($data: ChangeUserPasswordPayload!) {
    changeUserPassword(data: $data) {
      id
      status
    }
  }
`

export const useChangeUserPasswordMutation = () => {
  const tuple = useMutation<{ changeUserPassword: User }, { data: ChangeUserPasswordPayload }>(CHANGE_USER_PASSWORD)

  return tuple
}
