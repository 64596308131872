import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useCompanyList } from '../data/Queries'
import { Company, CompanyType } from '../models/Company'

export interface CompanyAutocompleteProps<Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>
  extends Omit<AutocompleteFormProps<Company, Multiple, DisableClearable, FreeSolo>, 'options' | 'loading'> {
  type?: CompanyType[]
  onOptionsLoaded?: (options: Company[]) => void
}

export const CompanyAutocomplete = <Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>({
  type,
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value?.id,
  onOptionsLoaded,
  ...props
}: CompanyAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useCompanyList({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onOptionsLoaded?.(data.listCompany)
    },
    variables: { data: { companyType: type } },
  })

  const options = React.useMemo(() => {
    return data?.listCompany ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}
