import * as React from 'react'
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { Status } from 'models/BaseModel'
import { useId } from 'shared/hooks'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  badge: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 5,
  },
  [Status.active]: {
    background: theme.palette.success.main,
  },
  [Status.inactive]: {
    background: theme.palette.text.disabled,
  },
  button: {
    textTransform: 'capitalize',
  },
}))

const statusTexts: Record<Status, string> = {
  [Status.active]: 'Activo',
  [Status.inactive]: 'Inactivo',
  [Status.archived]: 'Archivado',
}

const validStatus = Object.keys(statusTexts).filter(key => key !== Status.archived) as Status[]

export interface StatusMenuProps {
  value: Status
  onChange?: (newStatus: Status) => void
  disabled?: boolean
}

export const StatusMenu = ({ disabled, value, onChange }: StatusMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>(null)

  const classes = useStyles()

  const id = useId(null, 'StatusDropDown')

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = ev => {
    setAnchorEl(ev.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (newStatus: Status) => {
    onChange?.(newStatus)
    handleClose()
  }

  return (
    <>
      <Button className={classes.button} aria-controls={id} aria-haspopup="true" onClick={handleClick} disabled={disabled}>
        <span className={clsx(classes.badge, classes[value])}></span> {statusTexts[value]}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {validStatus
          .filter(key => key !== value)
          .map(key => {
            return (
              <MenuItem key={key} onClick={() => handleChange(key)}>
                <span className={clsx(classes.badge, classes[key])}></span>
                {statusTexts[key]}
              </MenuItem>
            )
          })}
      </Menu>
    </>
  )
}
