import * as React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Form, TextFieldForm } from 'shared/forms'
import { useUpdateUserMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetUser } from '../data/Queries'
import { ProfilesTransferList } from './ProfilesTransferList'
import { PermissionsTransferList } from './PermissionsTransferList'
import { UserProfile } from 'auth/models/UserProfile'
import { Permission } from 'auth/models/Permission'

interface UpdateForm {
  firstName: string
  lastName: string
}

const useStyles = makeStyles({
  form: {
    width: 800,
    margin: '0 auto',
  },
})

const schema = yup.object().shape({
  firstName: yup.string().required('El nombre es un campo requerido'),
  lastName: yup.string().required('El apellido es un campo requerido'),
})

const formId = 'UpdateUser'
const dialogId = 'UserEditFormDialog'

export const Update = () => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = React.useState(true)

  const [profiles, setProfiles] = React.useState<UserProfile[]>([])
  const [permissions, setPermissions] = React.useState<Permission[]>([])

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetUser({ variables: { id }, fetchPolicy: 'cache-and-network' })
  const [updateUser, { loading: isUpdating }] = useUpdateUserMutation()

  const loading = isGetting || isUpdating

  const user = React.useMemo(() => {
    return data?.getUser
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (user) {
      setProfiles(user.profiles ?? [])
      setPermissions(user.permissions ?? [])
      reset(user)
    }
  }, [user, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener el usuario. Intentelo más tarde'

      if (error.message === 'User not found') {
        msg = 'Usuario no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/users')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      const arePermissionsDirty = user.permissions !== permissions
      const areProfilesDirty = user.profiles !== profiles
      if (!isDirty && !arePermissionsDirty && !areProfilesDirty) {
        return
      }

      await updateUser({
        variables: {
          data: {
            id: user.id,
            ...values,
            profiles: profiles.map(profile => profile.id),
            permissions: permissions.map(permission => permission.id),
          },
        },
      })

      enqueueSnackbar('Usuario actualizado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al actualizar el usuario. Intentelo más tarde', { variant: 'error' })
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Editar usuario</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId, className: classes.form }}>
          <h3>Datos generales</h3>
          <Divider className="mt-4 mb-16" />
          <TextFieldForm
            name="firstName"
            label="Nombre"
            placeholder="Ingresar nombre"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <TextFieldForm
            name="lastName"
            label="Apellidos"
            placeholder="Ingresar apellidos"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          />
          {user ? (
            <>
              <h3 className="mt-8">Asignar perfil</h3>
              <Divider className="mt-4 mb-16" />
              <ProfilesTransferList initialValue={user.profiles} onSelectionChanged={setProfiles}></ProfilesTransferList>
              <h3 className="mt-8">Asignar permisos</h3>
              <Divider className="mt-4 mb-16" />
              <PermissionsTransferList initialValue={user.permissions} onSelectionChanged={setPermissions}></PermissionsTransferList>
            </>
          ) : null}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
