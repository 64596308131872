import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { BankAccount } from '../models/BankAccount'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteBankAccountMutation } from '../data/Mutations'

export interface DeleteProps {
  accounts: BankAccount[]
}

const dialogId = 'BankAccountDeleteDialog'

export const Delete = ({ accounts }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteBankAccount] = useDeleteBankAccountMutation()

  const deleteCards = async () => {
    try {
      await Promise.all(accounts.map(u => deleteBankAccount({ variables: { id: u.id } })))
      enqueueSnackbar(`${accounts.length} cuentas eliminadas correctamente`, { variant: 'success' })
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(`Ha ocurrido un error al eliminar las cuentas . Intentelo más tarde`, { variant: 'error' })
    }
  }

  return (
    <>
      <Tooltip title="Eliminar cuentas">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onClose={close} aria-labelledby={dialogId}>
        <DialogTitle id={dialogId}>Eliminar cuentas</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Deseas eliminar las siguientes cuentas?</DialogContentText>
          <List dense>
            {accounts.map(company => (
              <ListItem key={company.id}>
                <ListItemText primary={`- ${company.name}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteCards} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
