import * as React from 'react'
import MobileDetect from 'mobile-detect'
import { useCombinedRefs } from 'shared/hooks'
import { Dict } from 'core/utils'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { useLocation } from 'react-router-dom'

export type ScrollbarListener = () => void

type DivProps = JSX.IntrinsicElements['div']

export interface ScrollbarsProps extends DivProps {
  onScrollY?: ScrollbarListener
  onScrollX?: ScrollbarListener
  onScrollUp?: ScrollbarListener
  onScrollDown?: ScrollbarListener
  onScrollLeft?: ScrollbarListener
  onScrollRight?: ScrollbarListener
  onYReachStart?: ScrollbarListener
  onYReachEnd?: ScrollbarListener
  onXReachStart?: ScrollbarListener
  onXReachEnd?: ScrollbarListener
  scrollToTopOnRouteChange?: boolean
  enable?: boolean
}

const md = new MobileDetect(window.navigator.userAgent)
const isMobile = md.mobile()

const handlerNameByEvent: Dict = {
  'ps-scroll-y': 'onScrollY',
  'ps-scroll-x': 'onScrollX',
  'ps-scroll-up': 'onScrollUp',
  'ps-scroll-down': 'onScrollDown',
  'ps-scroll-left': 'onScrollLeft',
  'ps-scroll-right': 'onScrollRight',
  'ps-y-reach-start': 'onYReachStart',
  'ps-y-reach-end': 'onYReachEnd',
  'ps-x-reach-start': 'onXReachStart',
  'ps-x-reach-end': 'onXReachEnd',
}

Object.freeze(handlerNameByEvent)

const option = {
  wheelPropagation: true,
}

export const Scrollbars = React.forwardRef(
  ({ children, enable = true, scrollToTopOnRouteChange, ...props }: ScrollbarsProps & Dict, forwardedRef: React.Ref<HTMLDivElement>) => {
    const ps = React.useRef<PerfectScrollbar>()
    const handlerByEvent = React.useRef(new Map())

    const ref = useCombinedRefs(forwardedRef)

    const { pathname } = useLocation()

    const hookUpEvents = React.useCallback(() => {
      Object.keys(handlerNameByEvent).forEach(key => {
        const eventName = handlerNameByEvent[key]
        const callback = props[eventName]
        if (callback) {
          const handler = () => callback(ref.current)
          handlerByEvent.current.set(key, handler)
          ref.current?.addEventListener(key, handler, false)
        }
      })
    }, [props, ref])

    const unHookUpEvents = React.useCallback(() => {
      handlerByEvent.current.forEach((value, key) => {
        if (ref.current) {
          ref.current.removeEventListener(key, value, false)
        }
      })
      handlerByEvent.current.clear()
    }, [ref])

    const destroyPs = React.useCallback(() => {
      unHookUpEvents()

      if (!ps.current) {
        return
      }
      ps.current.destroy()
    }, [unHookUpEvents])

    const createPs = React.useCallback(() => {
      if (isMobile || !ref || ps.current || !ref.current) {
        return
      }

      ps.current = new PerfectScrollbar(ref.current, option)

      hookUpEvents()
    }, [hookUpEvents, ref])

    const scrollToTop = React.useCallback(() => {
      if (ref && ref.current) {
        ref.current.scrollTop = 0
      }
    }, [ref])

    React.useEffect(() => {
      function updatePs() {
        if (!ps.current) {
          return
        }
        ps.current.update()
      }

      updatePs()
    })

    React.useEffect(() => {
      createPs()
    }, [createPs])

    React.useEffect(() => {
      if (scrollToTopOnRouteChange) {
        scrollToTop()
      }
    }, [scrollToTop, pathname, scrollToTopOnRouteChange])

    React.useEffect(
      () => () => {
        destroyPs()
      },
      [destroyPs],
    )

    return (
      <div
        className={props.className}
        style={
          enable && !isMobile
            ? {
                position: 'relative',
                overflow: 'hidden',
              }
            : {}
        }
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
