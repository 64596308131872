import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useCreditList } from '../data/Queries'
import { Credit, CreditKind } from '../models/Credit'

export interface CreditAutocompleteProps<Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>
  extends Omit<AutocompleteFormProps<Credit, Multiple, DisableClearable, FreeSolo>, 'options' | 'loading'> {
  kind?: CreditKind[]
}

export const CreditAutocomplete = <Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>({
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value.id,
  kind,
  ...props
}: CreditAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useCreditList({
    fetchPolicy: 'network-only',
    variables: {
      data: kind ? { kind } : {},
    },
  })

  const options = React.useMemo(() => {
    return data?.listCredit ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}
