import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Credit, CreditKind } from '../models/Credit'

export const CreditFragment = gql`
  fragment CreditFragment on Credit {
    id
    name
    typeCredit {
      id
      name
    }
    organization {
      id
      name
    }
    company {
      id
      name
    }
    account {
      id
      initialBalance
      balance
    }
    totalDebt
    debt
    term
    paymentType
    interest
    initDate
    pay
    lastPaymentDate
    status
    createdAt
    tenantId
    kind
  }
`

const LIST_CREDIT = gql`
  query ListCredit($data: ListCreditPayload!) {
    listCredit(data: $data) {
      ...CreditFragment
    }
  }
  ${CreditFragment}
`
export type ListCreditResponse = { listCredit: Credit[] }
export interface ListCreditVars {
  data: {
    kind?: CreditKind[]
  }
}

export const useCreditList = (options?: QueryHookOptions<ListCreditResponse, ListCreditVars>) => {
  const result = useQuery<ListCreditResponse, ListCreditVars>(LIST_CREDIT, options)
  return result
}

const GET_CREDIT = gql`
  query GetCredit($id: String!) {
    getCredit(id: $id) {
      ...CreditFragment
    }
  }
  ${CreditFragment}
`
export type GetCreditResponse = { getCredit: Credit }
export interface GetCreditVars {
  id: string
}

export const useGetCredit = (options?: QueryHookOptions<GetCreditResponse, GetCreditVars>) => {
  const result = useQuery<GetCreditResponse, GetCreditVars>(GET_CREDIT, options)
  return result
}
