import * as React from 'react'
import * as yup from 'yup'
import { Button, Card, CardProps, Grid, MenuItem, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Form, SelectForm } from 'shared/forms'
import dayjs from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateNumberList } from 'core/utils'
import clsx from 'clsx'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { Organization } from 'modules/organization/models/Organization'
import { useConstant } from 'shared/hooks'
import { ListMonthByMonthVars } from './data/Queries'

export interface FilterProps extends CardProps {
  loading?: boolean
  onFilter: (payload: ListMonthByMonthVars['data'], values: FilterForm) => void
}

export interface FilterForm {
  organization?: Organization
  year: number
  month: number
  organization2?: Organization
  year2: number
  month2: number
}

const schema = yup.object().shape({
  organization: yup.object().nullable(),
  year: yup.number().required('Debe seleccionar un año'),
  month: yup.number().required('Debe seleccionar un mes'),
  organization2: yup.object().nullable(),
  year2: yup.number().required('Debe seleccionar un año'),
  month2: yup.number().required('Debe seleccionar un mes'),
})

const baseYear = 2021

const years = generateNumberList(dayjs().year() - baseYear + 1).map(n => n + baseYear)

const buildFilter = ({ organization, year, month }: Pick<FilterForm, 'month' | 'year' | 'organization'>) => {
  const monthDate = dayjs(`${month + 1}/${year}`, 'M/YYYY')

  return {
    organizationId: organization?.id,
    initDate: monthDate.startOf('month').toDate(),
    endDate: monthDate.endOf('month').toDate(),
  }
}

export const Filter = ({ onFilter, className, loading, ...props }: FilterProps) => {
  const now = dayjs()

  const defaultValues = useConstant(() => ({
    year: now.year(),
    month: now.month(),
    organization: null,
    year2: now.year(),
    month2: now.month(),
    organization2: null,
  }))

  const formInstance = useForm<FilterForm>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  React.useEffect(() => {
    onSubmit(defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  const onSubmit = (values: FilterForm) => {
    const { year, month, organization, year2, month2, organization2 } = values
    const filter = buildFilter({ year, month, organization })
    const filter2 = buildFilter({ year: year2, month: month2, organization: organization2 })
    onFilter({ first: filter, second: filter2 }, values)
  }

  return (
    <Card {...props} className={clsx('flex flex-row items-center p-8', className)}>
      <Typography className="flex-none" variant="body1">
        Filtrar listado
      </Typography>
      <Form formProps={{ className: 'flex-grow ml-48 flex flex-row items-center' }} onSubmit={onSubmit} {...formInstance}>
        <Grid container spacing={3}>
          <Grid item container xs={9} spacing={3}>
            <Grid item xs={4}>
              <SelectForm name="year" label="Seleccionar primer año" fullWidth disabled={loading} required>
                {years.map(year => {
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
            <Grid item xs={4}>
              <SelectForm name="month" label="Seleccionar primer mes" fullWidth disabled={loading} required>
                {dayjs.months().map((month, index) => {
                  return (
                    <MenuItem key={month} value={index}>
                      {month}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
            <Grid item xs={4}>
              <OrganizationAutocomplete
                name="organization"
                inputProps={{ label: 'Seleccionar primera organización', required: true }}
                placeholder="Buscar organización..."
                fullWidth
                disabled={loading}
              ></OrganizationAutocomplete>
            </Grid>
            <Grid item xs={4}>
              <SelectForm name="year2" label="Seleccionar segundo año" fullWidth disabled={loading} required>
                {years.map(year => {
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
            <Grid item xs={4}>
              <SelectForm name="month2" label="Seleccionar segundo mes" fullWidth disabled={loading} required>
                {dayjs.months().map((month, index) => {
                  return (
                    <MenuItem key={month} value={index}>
                      {month}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
            <Grid item xs={4}>
              <OrganizationAutocomplete
                name="organization2"
                inputProps={{ label: 'Seleccionar segunda organización', required: true }}
                placeholder="Buscar organización..."
                fullWidth
                disabled={loading}
              ></OrganizationAutocomplete>
            </Grid>
          </Grid>

          <Grid item xs={3} className="flex items-center">
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Card>
  )
}
