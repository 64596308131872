import * as React from 'react'
import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { IconNavButtonProps } from './components/IconNavButton'
import { WithPermissions } from 'auth/components/WithPermissions'
import { useCrudContext } from './CrudPage/CrudContext'

export type AddButtonProps = Omit<IconNavButtonProps, 'children' | 'to' | 'aria-label'> & { style?: React.CSSProperties }

export const AddButton = (props: AddButtonProps) => {
  const { url, name } = useCrudContext()

  return (
    <WithPermissions permissions={[`${name}.create`]}>
      <Button style={props.style} variant="contained" component={Link} to={`${url}/create`}>
        <Add></Add> {props.title}
      </Button>
    </WithPermissions>
  )
}
