import { gql, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client'
import { PaymentType } from 'modules/account/models/AccountPayment'
import { CategoryOutcomeType } from 'modules/category-move/models/CategoryMove'
import { Outcome, PaymentMethod } from '../models/Outcome'

export interface ListOutcomeResponse {
  listOutcome: Outcome[]
  listOutcomeTotals: {
    paymentMethod: PaymentMethod | null
    amount: number
  }[]
}

export interface ListOutcomePayload {
  initDate: Date
  endDate: Date
  accountType?: string
  organizationId?: string
  bankAccountId?: string
  creditCardId?: string
  creditId?: string
  categoryOutcomeType?: CategoryOutcomeType
  categoryId?: string
  paymentType?: PaymentType
  paymentId?: string
}

export const ListOutcomeFragment = gql`
  fragment ListOutcomeFragment on Outcome {
    id
    date
    notes
    hasTaxReceipt
    amount
    company {
      id
      name
    }
    category {
      id
      name
      parent {
        id
        name
      }
    }
    organization {
      id
      name
    }
    bankAccount {
      id
      name
      accountNumber
    }
    creditCard {
      id
      name
      accountNumber
    }
    credit {
      id
      name
    }
    accountPayment {
      credit {
        id
        name
      }
      creditCard {
        id
        name
      }
    }
    hasTaxReceipt
    isDirectDebit
    paymentMethod
    outcomeType
    status
    createdAt
    tenantId
  }
`

const LIST_OUTCOME = gql`
  query ListOutcome($data: ListOutcomePayload!) {
    listOutcomeTotals(data: $data) {
      paymentMethod
      amount
    }
    listOutcome(data: $data) {
      ...ListOutcomeFragment
    }
  }
  ${ListOutcomeFragment}
`

export const useOutcomeList = (options?: QueryHookOptions<ListOutcomeResponse, { data: ListOutcomePayload }>) => {
  const result = useLazyQuery<ListOutcomeResponse, { data: ListOutcomePayload }>(LIST_OUTCOME, options)
  return result
}

const GET_OUTCOME = gql`
  query GetOutcome($id: String!) {
    getOutcome(id: $id) {
      ...ListOutcomeFragment
    }
  }
  ${ListOutcomeFragment}
`
export type GetOutcomeResponse = { getOutcome: Outcome }
export interface GetOutcomeVars {
  id: string
}

export const useGetOutcome = (options?: QueryHookOptions<GetOutcomeResponse, GetOutcomeVars>) => {
  return useQuery<GetOutcomeResponse, GetOutcomeVars>(GET_OUTCOME, options)
}
