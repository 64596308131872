import { IconButton, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'

export interface IconNavButtonProps {
  to: string
  title: string
  'aria-label': string
}

export const IconNavButton: React.FC<IconNavButtonProps> = props => {
  return (
    <Tooltip title={props.title}>
      <IconButton component={Link} to={props.to} aria-label={props['aria-label']}>
        {props.children}
      </IconButton>
    </Tooltip>
  )
}
