import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { TypeCredit } from '../models/TypeCredit'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteTypeCreditMutation } from '../data/Mutations'

export interface DeleteProps {
  typeCredits: TypeCredit[]
}

const dialogId = 'TypeCreditDeleteDialog'

export const Delete = ({ typeCredits }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteTypeCredit] = useDeleteTypeCreditMutation()

  const deleteTypeCredits = async () => {
    try {
      await Promise.all(typeCredits.map(u => deleteTypeCredit({ variables: { id: u.id } })))
      enqueueSnackbar(`${typeCredits.length} tipos de crédito eliminados correctamente`, { variant: 'success' })
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(`Ha ocurrido un error al eliminar los tipos de crédito . Intentelo más tarde`, { variant: 'error' })
    }
  }

  return (
    <>
      <Tooltip title="Eliminar tipos de crédito">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onClose={close} aria-labelledby={dialogId}>
        <DialogTitle id={dialogId}>Eliminar tipos de crédito</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Deseas eliminar los siguientes tipos de crédito?</DialogContentText>
          <List dense>
            {typeCredits.map(typeCredit => (
              <ListItem key={typeCredit.id}>
                <ListItemText primary={`- ${typeCredit.name}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteTypeCredits} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
