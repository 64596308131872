import { IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { useNavbarMobileContext } from 'layout/contexts'
import * as React from 'react'

export const NavbarMobileToggleButton: React.FC<{ className?: string }> = props => {
  const { toggleNavbarMobile } = useNavbarMobileContext()
  return (
    <IconButton className={props.className} color="inherit" onClick={toggleNavbarMobile} disableRipple>
      {props.children || <MenuIcon color="action"></MenuIcon>}
    </IconButton>
  )
}
