import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { ListOutcomePayload, useOutcomeList } from '../data/Queries'
import { Outcome, OutcomeType, outcomeTypes, PaymentMethod, outcomePaymentMethodsLabels } from '../models/Outcome'
import { Column, Row } from 'react-table'
import { formatCurrency } from 'shared/utils'
import { Card, Grid, makeStyles, Typography } from '@material-ui/core'
import { Filter } from './Filter'
import { AmountSummary } from 'shared/reports'
import { Delete } from './Delete'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import { DeleteButton } from 'shared/crud/DeleteButton'
import { ExportCsvButton, ExportCsvButtonProps } from 'shared/crud/ExportCsvButton'
import { EditButton } from 'shared/crud/EditButton'

function getPaymentMethodName(term: PaymentMethod) {
  return outcomePaymentMethodsLabels[term]
}

function getOutcomeType(term: OutcomeType) {
  return outcomeTypes.find(pm => pm.value === term)?.text
}

function getCreditAccountName({ bankAccount, creditCard, paymentMethod, credit }: Outcome) {
  return creditCard?.name ? 'TC: ' + creditCard?.name : paymentMethod === 'credit' ? credit?.name : bankAccount?.name
}

const useStyles = makeStyles({
  notesField: {
    maxHeight: 60,
    overflowY: 'auto',
    whiteSpace: 'break-spaces',
    width: 200,
  },
})

export const List = () => {
  const [getList, { data: companyListData, loading }] = useOutcomeList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  const classes = useStyles()

  const columns = React.useMemo<Column<Outcome>[]>(() => {
    return [
      {
        Header: 'Fecha de egreso',
        accessor: 'date',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        id: 'outcomeType',
        Header: 'Tipo de egreso',
        accessor: ({ outcomeType }) => getOutcomeType(outcomeType) ?? '',
      },
      {
        id: 'company.name',
        Header: 'Proveedor / Acreedor',
        accessor: ({ company }) => company.name,
      },
      {
        Header: 'Monto',
        accessor: 'amount',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        id: 'paymentMethod',
        Header: 'Método de pago',
        accessor: ({ paymentMethod }) => getPaymentMethodName(paymentMethod) ?? '',
      },
      {
        Header: 'Cuenta de cargo',
        accessor: getCreditAccountName,
      },
      {
        Header: 'Cuenta de abono',
        accessor: ({ accountPayment }) => accountPayment?.credit?.name || accountPayment?.creditCard?.name,
      },
      {
        id: 'category.name',
        Header: 'Categoría',
        accessor: ({ category }) => (category?.parent?.name ? category?.parent?.name + ' - ' + category?.name : category?.name),
      },
      {
        id: 'organization.name',
        Header: 'Organización',
        accessor: ({ organization }) => organization?.name,
      },
      {
        id: 'isDirectDebit',
        Header: '¿Domiciliado?',
        accessor: ({ isDirectDebit }) => (isDirectDebit ? 1 : 0),
        Cell: ({ value }) => (value ? <Check></Check> : <Close></Close>),
      },
      {
        id: 'hasTaxReceipt',
        Header: '¿Comprobante fiscal?',
        accessor: ({ hasTaxReceipt }) => (hasTaxReceipt ? 1 : 0),
        Cell: ({ value }) => (value ? <Check></Check> : <Close></Close>),
      },
      {
        id: 'notes',
        Header: 'Notas',
        accessor: ({ notes }) => <p className={classes.notesField}>{notes}</p>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: outcome => (
          <div className="flex">
            <div className="mr-6">
              <EditButton title="Editar egreso" aria-label="editar egreso" id={outcome.id} tenantId={outcome.tenantId}></EditButton>
            </div>
            <DeleteButton>
              <Delete outcome={outcome}></Delete>
            </DeleteButton>
          </div>
        ),
      },
    ]
  }, [classes])

  const data = React.useMemo(() => {
    return companyListData?.listOutcome ?? []
  }, [companyListData])

  const totals = React.useMemo(() => {
    return companyListData?.listOutcomeTotals ?? []
  }, [companyListData])

  const tableInstance = useDataTable({
    selectable: false,
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<Outcome>>(() => {
    return {
      columns: [
        {
          id: 'date',
          header: 'Fecha de egreso',
          cell: row => dayjs(row.date).format('L'),
        },
        {
          id: 'outcomeType',
          header: 'Tipo de egreso',
          cell: row => getOutcomeType(row.outcomeType) ?? '',
        },
        {
          id: 'company',
          header: 'Proveedor / Acreedor',
          cell: row => row.company.name,
        },
        {
          id: 'amount',
          header: 'Monto',
          cell: row => row.amount,
        },
        {
          id: 'paymentMethod',
          header: 'Método de pago',
          cell: row => getPaymentMethodName(row.paymentMethod) ?? '',
        },
        {
          id: 'bankAccount',
          header: 'Cuenta de cargo',
          cell: getCreditAccountName,
        },
        {
          id: 'account',
          header: 'Cuenta de abono',
          cell: row => row.accountPayment?.credit?.name || row.accountPayment?.creditCard?.name,
        },
        {
          id: 'category',
          header: 'Categoría',
          cell: row => row.category?.name,
        },
        {
          id: 'organization',
          header: 'Organización',
          cell: row => row.organization.name,
        },
        {
          id: 'isDirectDebit',
          header: '¿Domiciliado?',
          cell: ({ isDirectDebit }) => (isDirectDebit ? 'Si' : 'No'),
        },
        {
          id: 'hasTaxReceipt',
          header: '¿Comprobante fiscal?',
          cell: ({ hasTaxReceipt }) => (hasTaxReceipt ? 'Si' : 'No'),
        },
        {
          id: 'notes',
          header: 'Notas',
          multiline: true,
          cell: row => row.notes,
        },
      ],
      filename: `Egresos - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data])

  const toolBarContent = (selectedRows: Row<Outcome>[]) => {
    return <ExportCsvButton {...csvProps}></ExportCsvButton>
  }

  const onFilter = (payload: ListOutcomePayload) => {
    getList({
      variables: {
        data: payload,
      },
    })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={9}>
          <Filter loading={loading} className="my-14 p-20" onFilter={onFilter}></Filter>
        </Grid>
        <Grid item md={3}>
          <Card className="my-14 p-20 flex flex-col md:max-w-640">
            <Typography variant="body1">Resumen</Typography>
            {totals.length ? (
              <AmountSummary
                summary={totals
                  .filter(t => !!t.paymentMethod)
                  .map(t => ({ name: getPaymentMethodName(t.paymentMethod), amount: t.amount }))}
                total={{
                  name: 'Total',
                  amount: totals.find(t => !t.paymentMethod)?.amount ?? 0,
                }}
              ></AmountSummary>
            ) : null}
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Card>
            <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
