import { useMutation, gql } from '@apollo/client'
import { Organization } from '../models/Organization'
import { Status } from 'models/BaseModel'

export interface ChangeOrganizationStatusPayload {
  id: string
  status: Status
}

const CHANGE_ORGANIZATION_STATUS = gql`
  mutation ChangeOrganizationStatus($data: ChangeOrganizationStatusPayload!) {
    changeOrganizationStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeOrganizationStatusMutation = () => {
  const tuple = useMutation<{ changeOrganizationStatus: Organization }, { data: ChangeOrganizationStatusPayload }>(
    CHANGE_ORGANIZATION_STATUS,
  )

  return tuple
}

export interface UpdateOrganizationPayload {
  id: string
  name?: string
  rfc?: string
  businessName?: string
  initialBalance?: number
}

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($data: UpdateOrganizationPayload!) {
    updateOrganization(data: $data) {
      id
      name
      rfc
      businessName
      account {
        id
        initialBalance
        balance
      }
      status
    }
  }
`

export const useUpdateOrganizationMutation = () => {
  const tuple = useMutation<{ updateOrganization: Organization }, { data: UpdateOrganizationPayload }>(UPDATE_ORGANIZATION)

  return tuple
}

const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: String!) {
    deleteOrganization(id: $id) {
      id
      status
    }
  }
`

export const useDeleteOrganizationMutation = () => {
  const tuple = useMutation<{ deleteOrganization: Organization }, { id: string }>(DELETE_ORGANIZATION)

  return tuple
}

export interface RegisterOrganizationPayload {
  name: string
  rfc?: string
  businessName: string
  balance: number
}

const REGISTER_ORGANIZATION = gql`
  mutation CreateOrganization($data: RegisterOrganizationPayload!) {
    registerOrganization(data: $data) {
      id
      name
      rfc
      businessName
      account {
        id
        initialBalance
        balance
      }
      status
    }
  }
`

export const useRegisterOrganizationMutation = () => {
  const tuple = useMutation<{ registerOrganization: Organization }, { data: RegisterOrganizationPayload }>(REGISTER_ORGANIZATION)

  return tuple
}
