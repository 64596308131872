import { useMutation, gql } from '@apollo/client'
import { CategoryMove, MoveType } from '../models/CategoryMove'
import { Status } from 'models/BaseModel'

export interface ChangeCategoryMoveStatusPayload {
  id: string
  status: Status
}

const CHANGE_CATEGORY_MOVE_STATUS = gql`
  mutation ChangeCategoryMoveStatus($data: ChangeCategoryMoveStatusPayload!) {
    changeCategoryMoveStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeCategoryMoveStatusMutation = () => {
  const tuple = useMutation<{ changeCategoryMoveStatus: CategoryMove }, { data: ChangeCategoryMoveStatusPayload }>(
    CHANGE_CATEGORY_MOVE_STATUS,
  )

  return tuple
}

export interface UpdateCategoryMovePayload {
  id: string
  name: string
  description: string
  parentId: string
}

const UPDATE_CATEGORY_MOVE = gql`
  mutation UpdateCategoryMove($data: UpdateCategoryMovePayload!) {
    updateCategoryMove(data: $data) {
      id
      name
      description
      status
      tenantId
    }
  }
`

export const useUpdateCategoryMoveMutation = () => {
  const tuple = useMutation<{ updateCategoryMove: CategoryMove }, { data: UpdateCategoryMovePayload }>(UPDATE_CATEGORY_MOVE)

  return tuple
}

const DELETE_CATEGORY_MOVE = gql`
  mutation DeleteCategoryMove($id: String!) {
    deleteCategoryMove(id: $id) {
      id
      status
    }
  }
`

export const useDeleteCategoryMoveMutation = () => {
  const tuple = useMutation<{ deleteCategoryMove: CategoryMove }, { id: string }>(DELETE_CATEGORY_MOVE)

  return tuple
}

export interface RegisterCategoryMovePayload {
  name: string
  description: string
  moveType: MoveType
  categoryMoveId?: string
}

const REGISTER_CATEGORY_MOVE = gql`
  mutation CreateCategoryMove($data: RegisterCategoryMovePayload!) {
    registerCategoryMove(data: $data) {
      id
      name
      description
      status
      tenantId
    }
  }
`

export const useRegisterCategoryMoveMutation = () => {
  const tuple = useMutation<{ registerCategoryMove: CategoryMove }, { data: RegisterCategoryMovePayload }>(REGISTER_CATEGORY_MOVE)

  return tuple
}
