import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useCreditCardList } from '../data/Queries'
import { CreditCard } from '../models/CreditCard'

export interface CreditCardAutocompleteProps<Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>
  extends Omit<AutocompleteFormProps<CreditCard, Multiple, DisableClearable, FreeSolo>, 'options' | 'loading'> {}

export const CreditCardAutocomplete = <Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>({
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value.id,
  ...props
}: CreditCardAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useCreditCardList({
    fetchPolicy: 'network-only',
  })

  const options = React.useMemo(() => {
    return data?.listCreditCard ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}
