import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useScheduleOutcomeList } from '../data/Queries'
import { ScheduleOutcome } from '../models/ScheduleOutcome'
import { Column, Row } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { StatusUpdate } from './StatusUpdate'
import { Delete } from './Delete'
import { Card } from '@material-ui/core'
import { DeleteButton } from 'shared/crud/DeleteButton'

export const List = () => {
  const { data: scheduleOutcomeListData } = useScheduleOutcomeList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const columns = React.useMemo<Column<ScheduleOutcome>[]>(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Categoría',
        accessor: ({ category }) => (category ? category.name : ''),
      },
      {
        Header: 'Compañía',
        accessor: ({ company }) => (company ? company.name : ''),
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Estatus',
        accessor: outcome => <StatusUpdate scheduleOutcome={outcome}></StatusUpdate>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: outcome => <EditButton title="Editar egreso" aria-label="editar egreso" id={outcome.id}></EditButton>,
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return scheduleOutcomeListData?.listScheduleOutcome ?? []
  }, [scheduleOutcomeListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const toolBarContent = (selectedRows: Row<ScheduleOutcome>[]) => {
    return selectedRows.length ? (
      <DeleteButton>
        <Delete scheduleOutcomes={selectedRows?.map(row => row.original)}></Delete>
      </DeleteButton>
    ) : null
  }

  return (
    <Card>
      <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
    </Card>
  )
}
