import * as React from 'react'

import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, makeStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { DataTable, useDataTable } from 'shared/common'
import { useDisclosure } from 'shared/hooks'
import { useAccountBalanceDetail } from './data/Queries'
import { Column } from 'react-table'
import { AccountMove } from 'modules/account/models/AccountMove'
import { formatCurrency } from 'shared/utils'
import dayjs from 'dayjs'
import { MoveType } from 'modules/category-move/models/CategoryMove'

export interface AccountBalanceDetailProps {
  accountId: string
  initDate: Date
  endDate: Date
}

const dialogId = 'ShowAccountBalanceDetail'

const useStyles = makeStyles({
  notesField: {
    maxHeight: 60,
    overflowY: 'auto',
    whiteSpace: 'break-spaces',
    width: 200,
  },
})

export const AccountBalanceDetail = ({ accountId, initDate, endDate }: AccountBalanceDetailProps) => {
  const classes = useStyles()

  const [getList, { data: listData }] = useAccountBalanceDetail({
    fetchPolicy: 'network-only',
  })

  const { isOpen, open, close } = useDisclosure({
    onOpen: () => {
      getList({
        variables: {
          account: accountId,
          data: {
            initDate,
            endDate,
          },
        },
      })
    },
  })

  const columns = React.useMemo<Column<AccountMove>[]>(() => {
    return [
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        id: 'notes',
        Header: 'Notas',
        accessor: ({ notes }) => <p className={classes.notesField}>{notes}</p>,
      },
      {
        Header: 'Saldo Inicial',
        accessor: 'initialBalance',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        id: 'outcome',
        Header: 'Cargo',
        accessor: ({ amount, moveType }) => (moveType === MoveType.outcome ? amount : 0),
        Cell: ({ value }) => (value ? formatCurrency(value) : ''),
      },
      {
        id: 'income',
        Header: 'Depósito',
        accessor: ({ amount, moveType }) => (moveType === MoveType.income ? amount : 0),
        Cell: ({ value }) => (value ? formatCurrency(value) : ''),
      },
      {
        Header: 'Saldo final',
        accessor: 'balance',
        Cell: ({ value }) => formatCurrency(value),
      },
    ]
  }, [classes])

  const data = React.useMemo(() => {
    return listData?.listAccountBalanceDetail ?? []
  }, [listData])

  const tableInstance = useDataTable({
    selectable: false,
    columns,
    data,
  })

  return (
    <>
      <Tooltip title="Mostrar listado de movimientos">
        <IconButton aria-label="detalle" onClick={open}>
          <VisibilityIcon></VisibilityIcon>
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={close} aria-labelledby={dialogId}>
        <DialogTitle id={dialogId}>Listado de movimientos</DialogTitle>
        <DialogContent>
          <DataTable {...tableInstance}></DataTable>
        </DialogContent>
      </Dialog>
    </>
  )
}
