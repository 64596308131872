import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, TextFieldForm } from 'shared/forms'
import { useRegisterUserProfileMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { PermissionsTransferList } from 'modules/user/components/PermissionsTransferList'
import { Permission } from 'auth/models/Permission'

interface CreateForm {
  name: string
  label: string
}

const useStyles = makeStyles({
  form: {
    width: 800,
    margin: '0 auto',
  },
})

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
  label: yup.string().required('La descripción es un campo requerido'),
})

const dialogId = 'userProfile/create-dialog'
const formId = 'userProfile/create-form'

export const Create = () => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = React.useState(true)
  const [permissions, setPermissions] = React.useState<Permission[]>([])

  const [addUserProfile, { loading }] = useRegisterUserProfileMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      label: '',
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await addUserProfile({
        variables: {
          data: {
            ...values,
            permissions: permissions.map(permission => permission.id),
          },
        },
      })

      enqueueSnackbar('Perfil de usuario registrado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al registrar el perfil de usuario. Intentelo más tarde', { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/user-profiles')
  }

  return (
    <Dialog fullWidth maxWidth="md" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Registrar perfil de usuario</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId, className: classes.form }} onSubmit={onSubmit} {...formInstance}>
          <h3>Datos generales</h3>
          <Divider className="mt-4 mb-16" />
          <TextFieldForm
            name="name"
            label="Nombre"
            placeholder="Ingresar nombre"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <TextFieldForm
            name="label"
            label="Descripción"
            placeholder="Ingresar descripción"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          />
          <h3 className="mt-8">Asignar permisos</h3>
          <Divider className="mt-4 mb-16" />
          <PermissionsTransferList onSelectionChanged={setPermissions}></PermissionsTransferList>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
