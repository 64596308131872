import { useMutation, gql } from '@apollo/client'
import { Company, CompanyType } from '../models/Company'
import { Status } from 'models/BaseModel'

export interface ChangeCompanyStatusPayload {
  id: string
  status: Status
}

const CHANGE_COMPANY_STATUS = gql`
  mutation ChangeCompanyStatus($data: ChangeCompanyStatusPayload!) {
    changeCompanyStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeCompanyStatusMutation = () => {
  const tuple = useMutation<{ changeCompanyStatus: Company }, { data: ChangeCompanyStatusPayload }>(CHANGE_COMPANY_STATUS)

  return tuple
}

export interface UpdateCompanyPayload {
  id: string
  name: string
  businessName: string
  contact: string
  rfc?: string
  description: string
  phone: string
  companyType: CompanyType[]
}

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($data: UpdateCompanyPayload!) {
    updateCompany(data: $data) {
      id
      name
      businessName
      rfc
      contact
      description
      phone
      companyType
      status
      tenantId
    }
  }
`

export const useUpdateCompanyMutation = () => {
  const tuple = useMutation<{ updateCompany: Company }, { data: UpdateCompanyPayload }>(UPDATE_COMPANY)

  return tuple
}

const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: String!) {
    deleteCompany(id: $id) {
      id
      status
    }
  }
`

export const useDeleteCompanyMutation = () => {
  const tuple = useMutation<{ deleteCompany: Company }, { id: string }>(DELETE_COMPANY)

  return tuple
}

export interface RegisterCompanyPayload {
  name: string
  rfc?: string
  businessName: string
  email: string
  phone: string
  companyType: CompanyType[]
}

const REGISTER_COMPANY = gql`
  mutation CreateCompany($data: RegisterCompanyPayload!) {
    registerCompany(data: $data) {
      id
      name
      rfc
      businessName
      email
      phone
      companyType
      status
      tenantId
    }
  }
`

export const useRegisterCompanyMutation = () => {
  const tuple = useMutation<{ registerCompany: Company }, { data: RegisterCompanyPayload }>(REGISTER_COMPANY)

  return tuple
}
