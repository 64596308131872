import * as React from 'react'
import { useBankList } from '../data/Queries'
import { SelectForm } from 'shared/forms'
import { MenuItem } from '@material-ui/core'
import { Bank } from '../models/Bank'

export interface BankSelectProps {
  name?: string
  disabled?: boolean
  defaultBank?: Bank
}

export const BankSelect = ({ name, disabled, defaultBank }: BankSelectProps) => {
  const { loading, data } = useBankList({
    fetchPolicy: 'network-only',
  })

  const banks = React.useMemo(() => {
    const listDefault = defaultBank ? [defaultBank] : []
    return data?.listBank ?? listDefault
  }, [data, defaultBank])

  return (
    <SelectForm name={name} label="Banco" variant="outlined" className="mb-16" required fullWidth disabled={loading || disabled}>
      {banks.map(b => (
        <MenuItem key={b.id} value={b.id}>
          {b.name}
        </MenuItem>
      ))}
    </SelectForm>
  )
}
