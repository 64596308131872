import { ScheduleOutcome } from '../models/ScheduleOutcome'
import { Status } from 'models/BaseModel'
import { StatusMenu } from 'shared/crud'
import { useChangeScheduleOutcomeStatusMutation } from '../data/Mutations'

export interface StatusUpdateProps {
  scheduleOutcome: ScheduleOutcome
}

export const StatusUpdate = ({ scheduleOutcome }: StatusUpdateProps) => {
  const { id, status, tenantId } = scheduleOutcome

  const [updateStatus, { loading }] = useChangeScheduleOutcomeStatusMutation()

  const handleChange = (newStatus: Status) => {
    updateStatus({ variables: { data: { id, status: newStatus } } })
  }

  return <StatusMenu value={status} onChange={handleChange} disabled={loading || !tenantId}></StatusMenu>
}
