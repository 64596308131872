import * as React from 'react'
import { CategoryAmountCompareFilter, useCompareCategoryAmounts } from '../data/UseCompareCategoryAmounts'

type CommonFilters = 'first' | 'second'

export type CategoryAmountCommonFilter = Pick<CategoryAmountCompareFilter, CommonFilters>

export type UseCategoryAmountTableProps = Omit<CategoryAmountCompareFilter, CommonFilters>

export const useCategoryAmountCompareTable = ({ moveType, categoryOutcomeType }: UseCategoryAmountTableProps) => {
  const [fetch, state] = useCompareCategoryAmounts({
    fetchPolicy: 'network-only',
  })

  const newFetch = React.useCallback(
    (filter: CategoryAmountCommonFilter) => {
      fetch({
        variables: {
          data: {
            ...filter,
            moveType,
            categoryOutcomeType,
          },
        },
      })
    },
    [fetch, moveType, categoryOutcomeType],
  )

  return [newFetch, state] as [typeof newFetch, typeof state]
}
