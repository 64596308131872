import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useUpdateCompanyMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useGetCompany } from '../data/Queries'
import { CompanyType, companyTypes } from '../models/Company'
import { CompanyTypeItem } from './CompanyTypeItem'

interface UpdateForm {
  name: string
  businessName: string
  contact: string
  description: string
  phone: string
  rfc?: string
  companyType: CompanyType[]
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la compañía es un campo requerido'),
  businessName: yup.string().required('La razón social de la compañía es un campo requerido'),
  contact: yup.string().isOptional(),
  description: yup.string().isOptional(),
  // eslint-disable-next-line no-template-curly-in-string
  phone: yup.string().min(10, 'El mínimo de caracteres es ${min}').required('El teléfono es un campo requerido'),
  companyType: yup.array().min(1, 'Debes elegir al menos un tipo de compañía').required('El tipo de compañía es requerido'),
  rfc: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(12, 'El mínimo de caracteres es ${min}')
    .isOptional(),
})

const formId = 'UpdateCompany'
const dialogId = 'CompanyEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetCompany({ variables: { id }, fetchPolicy: 'cache-and-network' })
  const [updateCompany, { loading: isUpdating }] = useUpdateCompanyMutation()

  const loading = isGetting || isUpdating

  const company = React.useMemo(() => {
    return data?.getCompany
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const { url } = useRouteMatch()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      businessName: '',
      rfc: '',
      contact: '',
      description: '',
      phone: '',
      companyType: [],
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (company) {
      reset(company)
    }
  }, [company, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener la compañía. Intentelo más tarde'

      if (error.message === 'Company not found') {
        msg = 'Compañía no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push(url.replace(`/edit/${id}`, ''))
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await updateCompany({
        variables: { data: { id: company.id, ...values } },
      })

      enqueueSnackbar('Compañía actualizada correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Ha ocurrido un error al actualizar la compañía. Intentelo más tarde', { variant: 'error' })
    }
  }

  const renderSelectedTypes = (selected: string[]) => {
    return selected.map(s => companyTypes.find(ct => ct.value === s).text).join(', ')
  }

  return (
    <Dialog fullWidth maxWidth="sm" disableBackdropClick open={isOpen} onExited={onExited} onClose={onClose} aria-labelledby={dialogId}>
      <DialogTitle id={dialogId}>Editar compañía</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="name"
                label="Nombre"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                name="businessName"
                label="Razón social"
                placeholder="Ingresar razón social"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="rfc"
                label="RFC"
                placeholder="Ingresar rfc"
                variant="outlined"
                className="mb-16"
                fullWidth
                transform={{
                  output: ev => ev.target.value.toUpperCase(),
                }}
                inputProps={{
                  maxLength: 13,
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldForm
                name="contact"
                label="Nombre del contacto"
                placeholder="Ingresar nombre del contacto"
                variant="outlined"
                className="mb-16"
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <TextFieldForm
            name="description"
            label="Descripción"
            placeholder="Ingresar descripción"
            variant="outlined"
            className="mb-16"
            rows={3}
            multiline
            fullWidth
            disabled={loading}
          />

          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="phone"
                label="Teléfono"
                placeholder="Ingresar teléfono"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                inputProps={{
                  maxLength: 10,
                }}
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <SelectForm
                name="companyType"
                label="Tipo"
                variant="outlined"
                className="mb-16"
                multiple
                required
                fullWidth
                renderValue={renderSelectedTypes}
                disabled={loading}
              >
                {companyTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    <CompanyTypeItem {...type}></CompanyTypeItem>
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
