import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client'
import { CategoryMove } from 'modules/category-move/models/CategoryMove'
import { IncomeAndOutcomeDayPayload } from 'modules/dashboard/data/Queries'
import { ReportBaseFilter } from 'modules/reports/account-balance/data/Queries'

export interface MonthByMonthResult {
  category: CategoryMove
  first: number
  second: number
  diff: number
  children: MonthByMonthResult[]
}

export interface ListMonthByMonthResponse {
  listIncomesMonthByMonth: MonthByMonthResult[]
  listOutcomesMonthByMonth: MonthByMonthResult[]
}

export interface ListMonthByMonthVars {
  data: { first: ReportBaseFilter; second: ReportBaseFilter }
}

export const MonthByMonthFragment = gql`
  fragment MonthByMonthFragment on MonthByMonthResult {
    category {
      id
      name
    }
    first
    second
    diff
    children {
      category {
        id
        name
      }
      first
      second
      diff
      children {
        category {
          id
          name
        }
        first
        second
        diff
      }
    }
  }
`

const LIST_INCOMES = gql`
  query ListIncomesMonthByMonth($data: MonthByMonthPayload!) {
    listIncomesMonthByMonth(data: $data) {
      ...MonthByMonthFragment
    }
  }
  ${MonthByMonthFragment}
`

export const useMonthByMonthIcomesList = (options?: QueryHookOptions<ListMonthByMonthResponse, ListMonthByMonthVars>) => {
  const result = useLazyQuery<ListMonthByMonthResponse, ListMonthByMonthVars>(LIST_INCOMES, options)
  return result
}

const LIST_OUTCOMES = gql`
  query ListOutcomesMonthByMonth($data: MonthByMonthPayload!) {
    listOutcomesMonthByMonth(data: $data) {
      ...MonthByMonthFragment
    }
  }
  ${MonthByMonthFragment}
`

export const useMonthByMonthOutcomesList = (options?: QueryHookOptions<ListMonthByMonthResponse, ListMonthByMonthVars>) => {
  const result = useLazyQuery<ListMonthByMonthResponse, ListMonthByMonthVars>(LIST_OUTCOMES, options)
  return result
}

export interface ListMonthByMonthChartResponse {
  getIncomeAndOutcomeDay: {
    name: string
    income: number
    outcome: number
  }[]
}

export interface ListMonthByMonthChartVars {
  chart: IncomeAndOutcomeDayPayload
}

const CHART_TOTALS = gql`
  query GetIncomeAndOutcome($chart: IncomeAndOutcomeDayPayload!) {
    getIncomeAndOutcomeDay(data: $chart) {
      name
      income
      outcome
    }
  }
`

export const useMonthByMonthChart = (options?: QueryHookOptions<ListMonthByMonthChartResponse, ListMonthByMonthChartVars>) => {
  const result = useLazyQuery<ListMonthByMonthChartResponse, ListMonthByMonthChartVars>(CHART_TOTALS, options)
  return result
}
