import * as React from 'react'
import { Icon, IconButton } from '@material-ui/core'
import { useNavbarContext } from 'layout/contexts'

export const NavbarFoldedToggleButton: React.FC<{ className?: string }> = ({ className }) => {
  const { toggleFolded } = useNavbarContext()

  return (
    <IconButton className={className} onClick={toggleFolded} color="inherit" size="small">
      <Icon fontSize="inherit">push_pin</Icon>
    </IconButton>
  )
}
